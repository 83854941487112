import { InsurancePolicyActionTypes } from "./InsurancePolicyTypes";

//below are functions that take an input and then return a plain action method
export function createInsurancePolicy(input: any, callback: any) {
	return {
		//return an action
		type: InsurancePolicyActionTypes.CREATE_INSURANCEPOLICY,
		payload: { input: input }, //input is data to submit (email, name, preferredLanguage)
		meta: { callbackAction: callback }
		//meta: extra function you want to do after the state is changed
	};
}

//below are functions that take an input and then return a plain action method
export function checkInsuranceBuyerEmail(email: string, callback: any) {
	return {
		//return an action
		type: InsurancePolicyActionTypes.CHECK_INSURANCE_BUYER_EMAIL,
		payload: { email: email }, //email is data to submit (email)
		meta: { callbackAction: callback }
		//meta: extra function you want to do after the state is changed
	};
}

export function updateInsurancePolicy(input: any, callback: any) {
	return {
		//return an action
		type: InsurancePolicyActionTypes.UPDATE_INSURANCEPOLICY,
		payload: { input: input }, //input is data to submit (email, name, preferredLanguage)
		meta: { callbackAction: callback }
		//meta: extra function you want to do after the state is changed
	};
}

export function getInsurancePolicies(input: string) {
	return {
		type: InsurancePolicyActionTypes.FETCH_INSURANCEPOLICIES,
		payload: { input: input }
	};
}
