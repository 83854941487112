//reducer is used to change state based on type of action and previous state
import {
	// combineReducers,
	Reducer
	//  AnyAction
} from "redux";
import { NewsLetterState, NewsLetterActionTypes } from "./NewsLetterTypes";

//create Type-safe initialState
export const initialState: NewsLetterState = {
	newsletters: []
};

//here we must need redux 4, otherwise Reducer does not take AnyAction as second argument type
//this postsReducer here is a function that takes the state and action to update the state
const newsletterReducer: Reducer<NewsLetterState> = (state = initialState, action: any) => {
	switch (action.type) {
		//case fetchRecentSearchesSuccessType:
		case NewsLetterActionTypes.FETCH_NEWSLETTERS_SUCCESS:
			return {
				...state,
				newsletters: action.myOptions //must pass back myOptions here from Thunk or Saga
			};

		//case fetchRecentSearchesErrorType:
		case NewsLetterActionTypes.FETCH_NEWSLETTERS_ERROR:
			return {
				...state,
				newsletters: []
			};

		//case createRecentSearchSuccessType:
		case NewsLetterActionTypes.CREATE_NEWSLETTER_SUCCESS:
			return state;

		case NewsLetterActionTypes.CREATE_NEWSLETTER_ERROR:
			//case createRecentSearchErrorType:
			return state;

		default:
			return state;
	}
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export default newsletterReducer;
