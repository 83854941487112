import * as React from "react";
import "./FilesUploader.scss";
import Axios from "axios";

interface PropsForSelf {
	showProgress?: boolean;
	showMessage?: boolean;
	showListOfFiles?: boolean;
	fileTypesAllowed?: string[]; //e.g. ["pdf", "docx"]
	onFilesUpdated?: (fileInfos: { id: number; name: string; filePath: string }[]) => void;
}

type IFileUploaderProps = PropsForSelf;

export default class FilesUploader extends React.Component<
	IFileUploaderProps,
	{
		loaded: number;
		selectedFiles: undefined[]; //to store selected files and id
		progressInfos: { id: number; percentage: number; fileName: string }[]; //to store progress (1-100%) and file name and id
		messages: any[]; //store messages to user on success/failure/delete operations
		fileInfos: { id: number; name: string; filePath: string }[]; //to store filenames uploaded and file paths and id
		nextId: number; //to store id of the selected files.
		//to allow mulitple times of selecting files
	}
> {
	constructor(props: any) {
		super(props);
		this.state = {
			loaded: 0,
			selectedFiles: [],
			progressInfos: [],
			messages: [],
			fileInfos: [],
			nextId: 0
		};
	}

	componentDidMount() {}

	handleSelectFiles = (event: any) => {
		//due to posssibly having multiple times of selecting files or re-selecting same files
		//we need to
		//	a) create a new Id for each file selected
		//	b) make sure the files selected is not already selected
		//	c) add the new files from the event.target.files to the selectedFiles array
		//	d) when hitting the upload button, we want to only upload the files not yet uploaded (progess <100%)

		//get the original list of files and info in the state
		const _progressInfos: any = [...this.state.progressInfos];
		const _selectedFiles: any = [...this.state.selectedFiles];
		const _fileInfos: any = [...this.state.fileInfos];
		let _messages = [...this.state.messages];

		const allowedExtensions = FileValidationServices.parseAllowedExtensions(this.props.fileTypesAllowed || []);

		//go through the newly selected files and check if not yet already selected
		//if not, then create new Id and add it to the selectedFiles array
		let nextId = this.state.nextId;
		if (event.target.files.length > 0) {
			for (let i = 0; i < event.target.files.length; i++) {
				//check if the file is already selected
				let fileExtensionValid: boolean = true;
				fileExtensionValid = FileValidationServices.isFileExtentionValid(event.target.files[i], allowedExtensions);
				//go to next file (next i index) if extention is invalid
				if (!fileExtensionValid) {
					let nextMessages = [
						..._messages,
						"File extension not valid. The allowed extensions are " +
							allowedExtensions +
							". We skipped:" +
							event.target.files[i].name
					];
					this.setState({
						messages: nextMessages
					});
					continue;
				}
				let alreadySelected = false;
				for (let j = 0; j < _selectedFiles.length; j++) {
					if (
						//a better test of idential files is to check the checksum of the file
						//checksum is the md5 of the file  (hash of the file context)
						_selectedFiles[j].file.name === event.target.files[i].name
					) {
						//if this file is already selected, then breakout the i loop
						alreadySelected = true;
						let nextMessages = [..._messages, "File already selected. We skipped: " + event.target.files[i].name];
						this.setState({
							messages: nextMessages
						});
						break;
					}
				}
				//if extention valid and also file not previously selected, add it to the selectedFiles
				if (!alreadySelected && fileExtensionValid) {
					nextId++;
					_selectedFiles.push({ id: nextId, file: event.target.files[i] });
					_fileInfos.push({ id: nextId, name: event.target.files[i].name, filePath: "" });
					_progressInfos.push({ id: nextId, percentage: 0, fileName: event.target.files[i].name });
				}
			}
		}
		this.setState({
			progressInfos: _progressInfos,
			selectedFiles: _selectedFiles,
			fileInfos: _fileInfos,
			nextId: nextId
		});
	};

	//update an individual file and report progress and list of files uploaded
	upload = (Id: number, file: any) => {
		let _progressInfos: any = [...this.state.progressInfos];
		let _fileInfos = [...this.state.fileInfos];
		let _messages = [...this.state.messages];

		//upload the file and get progres sfor the file
		UploadService.upload(file, (event: any) => {
			//check the Id and change the percentage of the only that matches the Id
			for (let i = 0; i < _progressInfos.length; i++) {
				if (_progressInfos[i].id === Id) {
					_progressInfos[i].percentage = Math.round((100 * event.loaded) / event.total);
				}
			}
			this.setState({
				progressInfos: _progressInfos
			});
		})
			.then((response: any) => {
				//here we add the new file name into the fileInfos directly
				for (let i = 0; i < _fileInfos.length; i++) {
					if (_fileInfos[i].id === Id) {
						_fileInfos[i].filePath = response.data.result.filePaths[0];
					}
				}
				let nextMessages = [..._messages, "Uploaded the file successfully: " + file.name];
				this.setState({
					messages: nextMessages,
					fileInfos: _fileInfos
				});

				//inform the caller the _fileInfos
				if (this.props.onFilesUpdated || typeof this.props.onFilesUpdated === "function") {
					this.props.onFilesUpdated(_fileInfos);
				}
			})
			.catch((error: any) => {
				//here we also need to check the Id and change the percentage or info
				for (let i = 0; i < _progressInfos.length; i++) {
					if (_progressInfos[i].id === Id) {
						_progressInfos[i].percentage = 0;
					}
				}
				for (let i = 0; i < _fileInfos.length; i++) {
					if (_fileInfos[i].id === Id) {
						_fileInfos[i].filePath = "";
					}
				}
				let nextMessages = [..._messages, "Could not upload the file: " + file.name];
				this.setState({
					progressInfos: _progressInfos,
					messages: nextMessages,
					fileInfos: _fileInfos
				});
				//inform the caller the _fileInfos
				if (this.props.onFilesUpdated || typeof this.props.onFilesUpdated === "function") {
					this.props.onFilesUpdated(_fileInfos);
				}
			});
	};

	handleUploadFiles = () => {
		const _selectedFiles: any[] = this.state.selectedFiles;
		let _progressInfos: any[] = this.state.progressInfos;
		//here we go through the list of selected files, and only upload
		//those that are not yet uploaded
		for (let i = 0; i < _selectedFiles.length; i++) {
			if (_progressInfos[i].percentage === 0) {
				this.upload(_selectedFiles[i].id, _selectedFiles[i].file);
			}
		}
	};

	handleDeleteFile = (Id: number) => {
		//Here we use the Id of the file to delete one file at a time
		//In the future we may have select and delete multiple files at once

		//make sure it is an array by shallow expansion and then
		//putting it back into the []
		let _progressInfos: any[] = [...this.state.progressInfos];
		let _fileInfos: any[] = [...this.state.fileInfos];
		let _selectedFiles: any[] = [...this.state.selectedFiles];
		let _messages: any[] = [...this.state.messages];

		//one file in the array to delete (make sure Id matches)
		let filesToDelete: { id: number; name: string; filePath: string }[] = [];
		for (let i = 0; i < _selectedFiles.length; i++) {
			if (_selectedFiles[i].id === Id) {
				filesToDelete.push({
					id: _selectedFiles[i].id,
					name: _fileInfos[i].name,
					filePath: _fileInfos[i].filePath
				});
			}
		}

		filesToDelete.forEach((fileIdNameAndPath: { id: number; name: string; filePath: string }) => {
			UploadService.delete(fileIdNameAndPath.name, fileIdNameAndPath.filePath)
				.then((response: any) => {
					//upon successful deletion, remove the file from the fileInfos and progressInfos and selectedFiles
					const newProgressInfos = _progressInfos.filter(
						(progressInfo: any) => progressInfo.id !== fileIdNameAndPath.id
					);

					const newfileInfos = _fileInfos.filter((fileInfo: any) => fileInfo.id !== fileIdNameAndPath.id);
					const newSelectedFiles = _selectedFiles.filter((file: any) => file.id !== fileIdNameAndPath.id);
					const nextMessages = [..._messages, "File deleted: " + fileIdNameAndPath.name];

					this.setState({
						progressInfos: newProgressInfos,
						fileInfos: newfileInfos,
						selectedFiles: newSelectedFiles,
						messages: nextMessages
					});

					//inform the caller the _fileInfos change
					if (this.props.onFilesUpdated || typeof this.props.onFilesUpdated === "function") {
						this.props.onFilesUpdated(newfileInfos);
					}
				})
				.catch((error: any) => {
					//if there is an error, just show the message
					const nextMessages = [..._messages, "Failed deleting file: " + fileIdNameAndPath.name];
					this.setState({
						messages: nextMessages
					});
				});
		});
	};
	render() {
		const { selectedFiles, progressInfos, messages, fileInfos } = this.state;
		const showListOfFiles: Boolean = this.props.showListOfFiles ? this.props.showListOfFiles : true;
		const showMessage: Boolean = this.props.showMessage ? this.props.showMessage : true;
		const showProgress: Boolean = this.props.showProgress ? this.props.showProgress : true;
		const fileTypesAllowed: string[] = this.props.fileTypesAllowed ? this.props.fileTypesAllowed : ["*"];

		return (
			<div className="FilesUploader">
				{showProgress &&
					progressInfos &&
					progressInfos.length > 0 &&
					progressInfos.map((progressInfo, index) => (
						<div className="filename-and-progress" key={index}>
							<div className="filename-and-delete">
								<div className="filename">
									<span>{progressInfo.fileName}</span>
								</div>
								<div className="delete-file">
									{progressInfo.percentage === 100 ? (
										<button
											className="btn-primary"
											onClick={() => {
												this.handleDeleteFile(progressInfo.id);
											}}
										>
											Delete
										</button>
									) : (
										<></>
									)}
								</div>
							</div>
							<div className="progress-bar" style={{ width: progressInfo.percentage + "%" }}>
								{progressInfo.percentage}%
							</div>
						</div>
					))}

				<div className="file-selection-and-upload">
					<div className="file-selection">
						<label className="btn-primary">
							<input className="input-file" type="file" multiple onChange={this.handleSelectFiles} />
							{"(" + fileTypesAllowed.join(",") + ")"}
						</label>
					</div>

					<div className="file-upload">
						<button className="btn-primary" disabled={!selectedFiles} onClick={this.handleUploadFiles}>
							Upload
						</button>
					</div>
				</div>
				{/* Messages of each file's uploading process */}
				{showMessage && messages.length > 0 && (
					<div className="alert alert-secondary" role="alert">
						<ul>
							{messages.map((item, i) => {
								return <li key={i}>{item}</li>;
							})}
						</ul>
					</div>
				)}
				{/* List of files */}
				{showListOfFiles && (
					<div className="card">
						<div className="card-header">List of Files</div>
						<ul className="list-group list-group-flush">
							{fileInfos &&
								fileInfos.length > 0 &&
								fileInfos.map((file, index) => (
									<li className="list-group-item" key={index}>
										{file.name}
									</li>
								))}
						</ul>
					</div>
				)}
			</div>
		);
	}
}

const UploadService: any = {
	upload: (file: any, onUploadProgress: any) => {
		let formData = new FormData();
		formData.append("file", file);
		const API_ENDPOINT = "/api/insurance/uploadInvoiceAndPackingList";
		return Axios.post(API_ENDPOINT, formData, {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "multipart/form-data"
			},
			onUploadProgress
		});
	},
	delete: (name: string, filePath: string) => {
		//name and the path of the file to delete on the server
		const API_ENDPOINT = "/api/insurance/deleteInvoiceOrPackingList";
		const data = { filePath: filePath, name: name };
		const config = {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json"
			}
		};
		return Axios.post(API_ENDPOINT, data, config);
	}
};

//file extention validataion referenced kendo-react-upload (npm package)
const FileValidationServices: any = {
	parseAllowedExtensions: (extensions: string[]) => {
		var allowedExtensions = extensions.map(function (ext: string) {
			var parsedExt = ext.substring(0, 1) === "." ? ext : "." + ext;
			return parsedExt.toLowerCase();
		});
		return allowedExtensions;
	},

	getFileExtension: (fileName: string) => {
		var rFileExtension = /\.([^.]+)$/; //good
		var matches = fileName.match(rFileExtension);
		return matches ? matches[0] : "";
	},
	isFileExtentionValid: (file: any, allowedExtensions: string[]) => {
		const INVALIDFILEEXTENSION: string = "invalidFileExtension";
		const fileName = file.name;
		const fileExtension = FileValidationServices.getFileExtension(fileName);
		let validationErrors: string[] = [];
		if (allowedExtensions.length > 0) {
			if (allowedExtensions.indexOf((fileExtension || "").toLowerCase()) < 0) {
				//if not finding the extension in the allowedExtensions array
				//add it as an invalid file extension in validationErrors
				if (validationErrors.indexOf(INVALIDFILEEXTENSION) < 0) {
					validationErrors.push(INVALIDFILEEXTENSION);
				}
			}
		}
		//if no errors (extention allowed) return true, otherwise return false
		return validationErrors.indexOf(INVALIDFILEEXTENSION) < 0 || false;
	}
};
