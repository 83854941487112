import * as React from "react";
// const logo = "/static/images/haymingwaylogo.png";
import validator from "validator";
// import { isEmail } from "validator";
import autobind from "autobind-decorator";
import "./InsuranceEnrollment.scss";
import Axios from "axios";
import { submitInsuranceEnrollment } from "../store/InsuranceBuyer/InsuranceBuyerActionsCreatorSaga";

//connect allows a component to use redux store as props of the component
import { connect } from "react-redux";

//react router allows one to navigate from within the component to other components
//import { RouteComponentProps } from "react-router-dom";

import { useNavigate } from "react-router-dom";
//
import { ApplicationState, ConnectedReduxProps } from "../store";
// props from redux store state (we will use the redux store state as if it was
// a props of this class component)

interface PropsFromState {
	//here is what we use from redux-store//state//InsuranceBuyerState
	email: string;
	name: string;
	// insurancepolicies: any[];
}

//-- new own props
interface PropsForSelf {
	navigate: any;
}

interface PropsFromDispatch {
	//allow uss to use functions from redux-actions as if they were
	//props of this class component
	submitInsuranceEnrollment: typeof submitInsuranceEnrollment;
}

interface IInsuranceEnrollmentState {
	name: string;
	email: string;
	emailError: boolean;
	emailAlreadyExists: boolean;
	nameError: boolean;
}

//--new combined props from state, own props, dispatch props -
//--as well as any props we want to pass - in a union type.
type IInsuranceEnrollmentProps = PropsFromState &
	PropsFromDispatch &
	PropsForSelf &
	//RouteComponentProps<any> & //RouteComponentProps<{}>
	ConnectedReduxProps; //ConnectedReduxProps here is simply dispatch

// =======================
class InsuranceEnrollmentDtl extends React.Component<
	IInsuranceEnrollmentProps, //no props
	IInsuranceEnrollmentState
> {
	constructor(props: any) {
		super(props);

		//initialize the state
		this.state = {
			name: "",
			email: "",
			// message: '',
			emailError: false,
			nameError: false,
			//  messageError: false,
			emailAlreadyExists: false
		};
	}

	async componentDidMount() {
		// await this.testSentenceTransfomer();
	}

	// testSentenceTransfomer = () => {
	// 	//count number of existing tasks in the database
	// 	const promise = Axios.get(`/bpi/word2vec/word2vec/semanticsearch`, {
	// 		// headers: { "Access-Control-Allow-Origin": "*" },
	// 		responseType: "json"
	// 	});
	// 	const dataPromise = promise
	// 		.then(async (r) => {
	// 			const rr = JSON.parse(r.data);
	// 			console.log("here is the response ", r.data);
	// 			console.log("here is the parsed data into JSON format:", rr);
	// 			const data1D = rr[0]; //take the first row of the 2D array
	// 			console.log("here is the type of  1D data", typeof data1D); //this is 1D array

	// 			//now lets spit out maximum of the 1D array
	// 			console.log("1D array here is:", data1D);
	// 			console.log("here is the maximum similarity:", Math.max(...data1D)); //the .values convert into an array
	// 			// return response.data.maxTaskID
	// 		})
	// 		.catch((err) => {
	// 			console.log("oops, we are seeing some error ", err);
	// 		});
	// 	return dataPromise;
	// };

	render() {
		return (
			<div>
				{/* {this.showHeaderPart()} */}

				<div className="newsletter-container">
					<div className="header-div">
						<h3 className="major">Enroll to Buy Insurance</h3>
					</div>
					<form>
						<div className="name-div">
							<label>Name</label>
							<span style={{ color: "red" }}>*</span>
							<div className="error">{this.state.nameError ? <div>{"Name cannot be empty"}</div> : ""}</div>
							<input type="text" name="name" placeholder="Your name.." required onChange={this.onNameChange}></input>
							<br></br>
						</div>
						<div className="email-div">
							<label>Email</label>
							<span style={{ color: "red" }}>*</span>
							<div className="error">{this.state.emailError ? <div>{"Please give valid email address"}</div> : ""}</div>
							<div className="error">
								{this.state.emailAlreadyExists ? <div>{"This email is already enrolled"}</div> : ""}
							</div>
							<input
								type="text"
								name="email"
								placeholder="Your email.."
								required
								onChange={this.onEmailChange}
								onBlur={this.handleVerifyEmail}
								//onClick={this.handleOnClick}
							></input>
							<br></br>
						</div>
						<div>
							<input
								className="submit-button"
								disabled={!this.state.email || this.state.emailError || this.state.emailAlreadyExists}
								type="submit"
								value="Submit"
								onClick={this.handleSubmitByRedux}
							></input>
							<br></br>
						</div>
					</form>
				</div>
			</div>
		);
	}

	@autobind
	private onNameChange(event: any) {
		event.preventDefault();
		if (!event.target.value.trim().length) {
			//String(event.target.value).length < 1
			this.setState({ nameError: true });
		} else {
			this.setState({ nameError: false });
			this.setState({ name: event.target.value });
		}
	}

	@autobind
	private onEmailChange(event: any) {
		event.preventDefault();
		this.setState({ emailAlreadyExists: false });
		if (!validator.isEmail(String(event.target.value).toLowerCase())) {
			this.setState({ emailError: true });
		} else {
			this.setState({ emailError: false });
			this.setState({ email: event.target.value });
		}
	}

	// @autobind
	// private handleSubmit(event: any) {
	// 	event.preventDefault();
	// 	if (this.state.email === "") {
	// 		return;
	// 	} else {
	// 		if (!validator.isEmail(String(this.state.email).toLowerCase())) {
	// 			alert("Invalid email address!");
	// 		}
	// 	}

	// 	if (this.state.name === "") {
	// 		// return;
	// 	}

	// 	if (this.state.preferredLanguage === "") {
	// 		this.setState({ preferredLanguageError: true });
	// 		return;
	// 	}

	// 	const backend_uri = "/api/subscriber/createSubscriber";
	// 	const data = {
	// 		name: this.state.name,
	// 		email: this.state.email
	// 	};

	// 	const config = {
	// 		headers: {
	// 			//'Access-Control-Allow-Origin': '*',
	// 			// 'Content-Type': 'application/json',
	// 		}
	// 	};

	// 	Axios.post(backend_uri, data, config)
	// 		.then(function (response) {
	// 			alert(
	// 				"Your subcription has been received. You will receive an email for confirmation. Please check your email. You will be redirected to Homepage sortly."
	// 			);
	// 			setTimeout(() => {
	// 				// window.location.href = "/index.html";
	// 				window.location.href = "/";
	// 			}, 3000);
	// 		})
	// 		.catch(function (error) {
	// 			alert("Failure porcessing your subscription. Please try again.");
	// 		});
	// }

	///todo(): handleVerifyEmail function
	@autobind
	private handleVerifyEmail(event: any) {
		event.preventDefault();
		if (this.state.email === "") {
			return;
		} else {
			if (!validator.isEmail(String(this.state.email).toLowerCase())) {
				alert("Invalid email address!");
			}
		}

		const backend_uri = "/api/insurance/verifyInsuranceBuyerByEmail";
		const data = {
			email: this.state.email
		};

		const config = {
			headers: {
				//'Access-Control-Allow-Origin': '*',
				// 'Content-Type': 'application/json',
			}
		};

		Axios.post(backend_uri, data, config)
			.then((response: any) => {
				if (response.data.success === true) {
					this.setState({ emailAlreadyExists: true });
					return true;
				} else {
					this.setState({ emailAlreadyExists: false });
					return false;
				}
			})
			.catch(function (error) {
				return null;
			});
	}

	@autobind
	private handleOnClick() {
		this.setState({ emailAlreadyExists: false });
	}

	@autobind
	private async handleSubmitByRedux(event: any) {
		event.preventDefault();
		if (this.state.email === "") {
			return;
		} else {
			if (!validator.isEmail(String(this.state.email).toLowerCase())) {
				alert("Invalid email address!");
			}
		}

		if (this.state.name === "") {
			// return;
		}

		//check one more time before submitting
		//const emailAlreadyExists =await this.handleVerifyEmail(event);

		//if (emailAlreadyExists||false){
		//	return;
		//}

		if (this.state.emailAlreadyExists) {
			return;
		}

		const input: any = {
			name: this.state.name,
			email: this.state.email
		};

		this.props.submitInsuranceEnrollment(input, this.showSuccessAfterSubmit);
		//call redux-saga to update store state

		// const backend_uri = "/api/subscriber/createSubscriber";
		// const data = {
		// 	name: this.state.name,
		// 	email: this.state.email
		// };

		// const config = {
		// 	headers: {
		// 		//'Access-Control-Allow-Origin': '*',
		// 		// 'Content-Type': 'application/json',
		// 	}
		// };

		// Axios.post(backend_uri, data, config)
		// 	.then(function (response) {
		// 		alert(
		// 			"Your subcription has been received. You will receive an email for confirmation. Please check your email. You will be redirected to Homepage sortly."
		// 		);
		// 		setTimeout(() => {
		// 			window.location.href = "/index.html";
		// 		}, 3000);
		// 	})
		// 	.catch(function (error) {
		// 		alert("Failure porcessing your subscription. Please try again.");
		// 	});
	}

	showSuccessAfterSubmit = () => {
		alert(
			"good news, your email has been enrolled, pls check your email to confirm. You will be redirected to Homepage shortly."
		);
		// setTimeout(() => {
		// 	// window.location.href = "/index.html";
		// 	window.location.href = "/";
		// }, 3000);
		//here we should update the state using
	};
}

//map props to state, such that if store.state.posts changes. the partialPosts as a prop
//here will also change, once it is changed, the view will be re-rendered

//--new map store state to props
const mapStateToProps = ({ insurancebuyer }: ApplicationState) => {
	return {
		//here ApplicationState.post is taken and mapped to partialPosts
		email: insurancebuyer.email,
		name: insurancebuyer.name
		// insurancepolicies:insurancebuyer.insurancepolicies
	};
};

// const mapDispatchToProps = (dispatch: Dispatch) => {
//   return {
//     //return an object that makes the getsearchPosts etc props as actions to the store
//     getSearchPosts: (input: string) => {
//       dispatch(getSearchPosts(input)); //dispatches the getSearchPosts defined in ActionCreator.tsx
//     },
//   };
// };

//map dispatch to props  especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = {
	submitInsuranceEnrollment: submitInsuranceEnrollment
};

//connect is used to create a component and get tthe state from the redux store
//and pass the data as props to the component

// Wrapping the main class with this functional component
function InsuranceEnrollment(props: any) {
	let navigate = useNavigate();
	return <InsuranceEnrollmentDtl {...props} navigate={navigate} />;
}

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InsuranceEnrollment) as any);

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceEnrollment);
