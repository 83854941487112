import * as React from "react";
import autobind from "autobind-decorator";
import validator from "validator";
import "./NewsLetterCompose.scss";
import Axios from "axios";
import { TextEditor } from "./TextEditor";
import PinEntry from "../utils/PinEntry";

export default class NewsLetterCompose extends React.Component<
	{},
	{
		title: string;
		textContent: string;
		contentError: boolean;
		titleError: boolean;
		newsletterLanguage: string;
		newsletterLanguageError: boolean;
		email: string;
		emailError: boolean;
		previewReady: boolean;
		approved: boolean;
		id: string;
		composerPin: string;
		isPinReady: boolean;
		isPinSent: boolean;
		pinEmail: string;
	}
> {
	_isMounted = false;
	constructor(props: any) {
		super(props);
		this.state = {
			title: "",
			textContent: "",
			// message: '',
			contentError: false,
			titleError: false,

			newsletterLanguage: "",
			newsletterLanguageError: false,
			//  messageError: false,
			email: "",
			emailError: false,
			previewReady: true,
			approved: false,
			id: "",
			composerPin: "",
			isPinReady: false,
			isPinSent: false,
			pinEmail: ""
		};
	}

	componentDidMount() {
		this._isMounted = true;
		// if (!this.state.isPinSent) {
		// this.sendPin();
		//this should be only done once, however it does not work
		//here with conditional rending in this component
		//we may have to pass this function to <PinEntry /> component instead
		// }
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		return (
			<div>
				<div className="container">
					<div className="header-div">
						<h3 className="major">Write Newsletter</h3>
					</div>
					<div className="preview-div">
						{this.state.previewReady ? (
							<div className="email-preview-button">
								<div className="email-div">
									{/* <label className="email-label">Email</label>
									<span className="email-span">*</span> */}
									<label className="email-label">
										Email
										<span className="error">{this.state.emailError ? "*" : ""}</span>
									</label>
									<input
										className="email-input"
										type="text"
										name="email"
										placeholder="Your email.."
										required
										onChange={this.onEmailChange}
										// value={this.state.email}
									></input>
								</div>
								<button className="preview-button" onClick={this.handlePreview}>
									Preview
								</button>
							</div>
						) : (
							<div></div>
						)}
						<div className="preview-checkbox">
							<div className="preview-checkbox-label">Approve:</div>
							<input
								className="checkbox-input"
								onChange={this.handleApproval}
								type="checkbox"
								name="preview-approved"
								value="Preview Approved"
								checked={this.state.approved}
							/>
						</div>
					</div>
					<div className="pin-div">{this.showPinEntry()}</div>
					<form>
						<div className="title-div">
							<label>Title</label>
							<span style={{ color: "red" }}>*</span>
							<div className="error">{this.state.titleError ? <div>{"Title cannot be empty"}</div> : ""}</div>
							<input
								type="text"
								name="Title"
								placeholder="Your news letter title..."
								required
								onChange={this.onTitleChange}
							></input>
							<br></br>
						</div>
						<div className="text-div">
							<label>Text</label>
							<span style={{ color: "red" }}>*</span>
							<div className="error">{this.state.contentError ? <div>{"Letter cannot be empty"}</div> : ""}</div>
							<TextEditor
								setFieldValue={(val) => {
									this.onContentChange(val);
								}}
								value={this.state.textContent}
							/>
						</div>
						<div className="language-div">
							<label>Language</label>
							<span style={{ color: "red" }}>*</span>
							<div className="error">
								{this.state.newsletterLanguageError ? <div>{"You must choose a language"}</div> : ""}
							</div>
							<label className="container">
								<input
									type="radio"
									name="radio"
									onClick={() => {
										if (this.state.newsletterLanguage !== "English") {
											this.setState({ approved: false });
										}
										this.setState({ newsletterLanguage: "English", newsletterLanguageError: false });
									}}
								/>
								English
								<span className="checkmark"></span>
							</label>
							<label className="container">
								<input
									type="radio"
									checked={this.state.newsletterLanguage === "Chinese"}
									name="radio"
									onClick={() => {
										if (this.state.newsletterLanguage !== "Chinese") {
											this.setState({ approved: false });
										}
										this.setState({ newsletterLanguage: "Chinese", newsletterLanguageError: false });
									}}
								/>
								Chinese
								<span className="checkmark"></span>
							</label>
							{/* <label className="container">
								<input
									type="radio"
									checked={this.state.newsletterLanguage == "Japanese"}
									name="radio"
									onClick={() => {
										if (this.state.newsletterLanguage != "Japanese") {
											this.setState({ approved: false });
										}
										this.setState({ newsletterLanguage: "Japanese", newsletterLanguageError: false });
									}}
								/>
								Japanese
								<span className="checkmark"></span>
							</label>
							<label className="container">
								<input
									type="radio"
									checked={this.state.newsletterLanguage == "Korean"}
									name="radio"
									onClick={() => {
												if (this.state.newsletterLanguage != "Korean") {
											this.setState({ approved: false });
										}
										this.setState({ newsletterLanguage: "Korean", newsletterLanguageError: false });
									}}
								/>
								Korean
								<span className="checkmark"></span>
							</label> */}
						</div>
						<div className="submit-div">
							<input
								type="submit"
								disabled={
									// !this.state.email ||
									// this.state.emailError ||
									this.state.newsletterLanguageError
								}
								value="Publish"
								onClick={this.handleSubmit}
							></input>
						</div>
					</form>
				</div>
			</div>
		);
	}

	sendPin = () => {
		//send the composer Pin for one time
		const backend_uri = "/api/newsletter/sendPin";
		const config = {
			headers: {
				//'Access-Control-Allow-Origin': '*',
				// 'Content-Type': 'application/json',
			}
		};
		Axios.get(backend_uri, config)
			.then((response) => {
				if (response.data.success === true && response.data.result && response.data.result.pinEmail) {
					this.setState({ isPinSent: true, pinEmail: response.data.result.pinEmail });
				} else {
					throw new Error("Error: failed getting composer pin sent");
				}
			})
			.catch((error) => {
				this.setState({ isPinSent: false, pinEmail: "" });
				alert("Failure sending pin to authorized composer." + error);
			});
	};

	@autobind showPinEntry() {
		const pinSentMessage = this.state.isPinSent
			? `A 6-digit pin has been sent to ${this.state.pinEmail}, please check and enter the pin here:`
			: "Please press Request Pin button to send pin ";
		const pinReadyMessage = "You are authorized to draft, preview and submit newsletter here!";
		// if (this.state.isPinReady)
		// 	return (
		// 		<div style={{ fontSize: 16, textAlign: "center", color: "green" }}>
		// 			You are authorized to draft, preview and submit newsletter here!
		// 		</div>
		// 	);
		// } else {
		return (
			<PinEntry
				isPinReady={this.state.isPinReady}
				sendPinFunc={this.sendPin}
				pinReadyMessage={pinReadyMessage}
				onPinReady={(pin: string) => {
					this.setState({ isPinReady: true, composerPin: pin });
				}}
				pinSentMessage={pinSentMessage}
			></PinEntry>
		);
		// }
	}

	@autobind
	private onTitleChange(event: any) {
		event.preventDefault();
		//as soon as we change the Title, we should make the preview button show up
		//and disable the approval
		this.setState({ approved: false });
		if (!event.target.value.trim().length) {
			this.setState({ titleError: true });
		} else {
			this.setState({ titleError: false, title: String(event.target.value).trim() });
		}
	}

	@autobind
	private onEmailChange(event: any) {
		event.preventDefault();
		if (!validator.isEmail(String(event.target.value).toLowerCase())) {
			this.setState({ emailError: true });
		} else {
			this.setState({ emailError: false });
			this.setState({ email: event.target.value });
		}
	}

	@autobind
	private onContentChange(val: any) {
		//as soon as we change the content, we should make the preview button show up
		//and disable the approval
		this.setState({ approved: false });

		if (!String(val).trim().length) {
			this.setState({ contentError: true });
		} else {
			this.setState({ contentError: false, textContent: String(val).trim() });
		}
	}

	@autobind
	private handleApproval(event: any) {
		if (this.state.id !== "" && this.state.isPinReady) {
			const oldStatus = this.state.approved;
			this.setState({ approved: !oldStatus });
		} else {
			alert("please finish the letter and preview first");
		}
	}

	@autobind
	private handleSubmit(event: any) {
		event.preventDefault();

		if (this.state.textContent === "") {
			this.setState({ contentError: true });
			return;
		} else {
			if (this.state.textContent.trim() === "<p></p>") {
				this.setState({ contentError: true });
				return;
			}
		}

		if (this.state.newsletterLanguage === "") {
			this.setState({ newsletterLanguageError: true });
			return;
		}

		if (this.state.approved === false) {
			alert("Please preview and check the Approve box first.");
			this.setState({ previewReady: true });
			return;
		}

		if (this.state.title === "") {
			this.setState({ titleError: true });
			return;
		} else {
			this.setState({ titleError: false });
		}

		if (this.state.id === "") {
			alert("Please write and preview letter first");
			return;
		}

		const backend_uri = "/api/newsletter/send";
		const data = {
			id: this.state.id,
			pin: this.state.composerPin
		};

		const config = {
			headers: {
				//'Access-Control-Allow-Origin': '*',
				// 'Content-Type': 'application/json',
			}
		};

		Axios.post(backend_uri, data, config)
			.then((response) => {
				if (response.data.success === true) {
					alert("Your news letter has been published. \n You can continue to write another one or quit.");
					this.setState({
						title: "",
						newsletterLanguage: "",
						titleError: false,
						contentError: false,
						// letterSent: true,
						approved: false,
						id: "",
						email: "",
						emailError: false,
						previewReady: true //enable preview again
					});
				} else {
					throw new Error("Error: failed sending newsletter");
				}
			})
			.catch((error) => {
				//here we check if error is saying textChanged after approval
				// this.setState({
				// 	approved: false
				// });

				// here we may want to call back-end  to delete the unsent newsletter with id
				// then reset everything to restart except the textContent
				this.setState({
					approved: false,
					previewReady: true, //enable preview again
					id: "",
					email: "",
					emailError: false
				});
				alert("Failure publishing your news letter. Please try again." + error);
			});
	}

	@autobind
	private handlePreview(event: any) {
		event.preventDefault();
		this.setState({ previewReady: true });
		if (this.state.email === "") {
			alert("Please enter email for sending preview to");
			return;
		} else {
			if (
				this.state.emailError
				// !validator.isEmail(String(this.state.email).toLowerCase())
			) {
				alert("Invalid email address!");
			}
		}
		if (this.state.textContent === "") {
			this.setState({ contentError: true });
			return;
		} else {
			if (this.state.textContent.trim() === "<p></p>") {
				this.setState({ contentError: true });
				return;
			}
		}
		if (this.state.newsletterLanguage === "") {
			this.setState({ newsletterLanguageError: true });
			return;
		}

		if (this.state.title === "") {
			this.setState({ titleError: true });
			return;
		} else {
			this.setState({ titleError: false });
		}

		const API_ENDPOINT = "/api/newsletter/preview";

		if (this.state.composerPin === "") {
			this.setState({ isPinReady: false });
			return;
		}

		const id = this.state.id || "";

		const data = {
			id: id, //if id already here, then we should use it to update the previous draft
			language: this.state.newsletterLanguage,
			title: this.state.title,
			textContent: this.state.textContent,
			email: this.state.email,
			pin: this.state.composerPin
		};

		const config = {
			headers: {
				//'Access-Control-Allow-Origin': '*',
				// 'Content-Type': 'application/json',
			}
		};

		Axios.post(API_ENDPOINT, data, config)
			.then((response: any) => {
				alert("Your message has been received for review.");
				if (
					response.data &&
					response.data.result &&
					response.data.result.newsletter &&
					response.data.result.newsletter._id
				) {
					this.setState({ previewReady: false, id: response.data.result.newsletter._id.toString() });
				}
			})
			.catch((error) => {
				alert("Failure sending your message. Please try again.");
			});
	}
}
