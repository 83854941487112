//actions here define the type and the action for each Type identified

// This type is basically shorthand for `{ [key: string]: any }`. Feel free to replace `any` with
// the expected return type of your API response.
//export type ApiResponse = Record<string, any>;

export const enum InsurancePolicyActionTypes {
	//crud (create, read, update, delete)

	//create
	CREATE_INSURANCEPOLICY = "@@insurancepolicies/CREATE_INSURANCEPOLICY",
	CREATE_INSURANCEPOLICY_SUCCESS = "@@insurancepolicies/CREATE_INSURANCEPOLICY_SUCCESS",
	CREATE_INSURANCEPOLICY_ERROR = "@@insurancepolicies/CREATE_INSURANCEPOLICY_ERROR",

	//read
	FETCH_INSURANCEPOLICIES = "@@insurancepolicies/FETCH_INSURANCEPOLICIES",
	FETCH_INSURANCEPOLICIES_SUCCESS = "@@insurancepolicies/FETCH_INSURANCEPOLICIES_SUCCESS",
	FETCH_INSURANCEPOLICIES_ERROR = "@@insurancepolicies/FETCH_INSURANCEPOLICIES_ERROR",

	CHECK_INSURANCE_BUYER_EMAIL = "@@insurancepolicies/CHECK_INSURANCE_BUYER_EMAIL",

	//update
	UPDATE_INSURANCEPOLICY = "@@insurancepolicies/UPDATE_INSURANCEPOLICY",
	UPDATE_INSURANCEPOLICY_SUCCESS = "@@insurancepolicies/UPDATE_INSURANCEPOLICY_SUCCESS",
	UPDATE_INSURANCEPOLICY_ERROR = "@@insurancepolicies/UPDATE_INSURANCEPOLICY_ERROR"

	//delete - todo
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability

// These are going to be in the redux state that the PostsReducer will modify
// upon dispatch with the associated data

export interface InsurancePolicyState {
	//here we should use real type rather than any***
	//readonly loading: boolean;
	//readonly data: any[];
	//readonly errors?: string;
	readonly insurancePolicy: any;
	readonly insurancePolicies: any[];
	// readonly id: string;
	// readonly insurance_buyer_id: string;
	// readonly policy_number: string;
	// readonly commodity_name: string;
	// readonly shipment_carrier: string;
	// readonly shipment_vessel: string;
	// readonly shipment_voyage: string;
	// readonly cargo_value: number;
	// readonly cargo_quantity: number;
	// readonly cargo_weight: number;
	// readonly cargo_number_of_containers: number;
	// readonly insured_value: number;
	// readonly beneficiary: string;
	// readonly port_of_discharge: string;
	// readonly port_of_loading: string;
	// readonly ETD: string;
	// readonly ETA: string;
	// readonly currency_of_claim: string;
	// readonly location_of_claim_payment: string;
	// readonly method_of_claim_payment: string;
	// readonly policy_choice: any[];
}
