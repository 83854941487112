import * as React from "react";
// const logo = "/static/images/haymingwaylogo.png";
import validator from "validator";
// import { isEmail } from "validator";
import autobind from "autobind-decorator";
import "./InsuranceOnBoard.scss";
// import Axios from "axios";
import { submitInsuranceOnBoard } from "../store/InsuranceBuyer/InsuranceBuyerActionsCreatorSaga";
//import { RouteComponentProps } from "react-router";
import { useParams } from "react-router-dom";

//connect allows a component to use redux store as props of the component
import { connect } from "react-redux";

//react router allows one to navigate from within the component to other components
//import { RouteComponentProps } from "react-router-dom";

import { useNavigate } from "react-router-dom";
//
import { ApplicationState, ConnectedReduxProps } from "../store";
// props from redux store state (we will use the redux store state as if it was
// a props of this class component)

interface PropsFromState {
	//here is what we use from redux-store//state//InsuranceBuyerState
	// email: string;
	// name: string;
	// insurancepolicies: any[];
	id: string;
	companyName: string;
	companyAddress: {
		city: String;
		streetLine1: String;
		streetLine2: String;
		streetLine3: String;
		houseNumber: String;
		zipCode: String;
	};
	companyNameError: boolean;
	companyPhoneNumber: String;
	companySize: String;
	companyWebsite: String;
	companyRegistrationYear: String;
	companyRevenue: String;
}

//-- new own props
interface PropsForSelf {
	match: {
		params: any;
	};
}

interface PropsFromDispatch {
	//allow uss to use functions from redux-actions as if they were
	//props of this class component
	submitInsuranceOnBoard: typeof submitInsuranceOnBoard;
}

interface IInsuranceOnBoardState {
	companyName: string;
	companyAddress: {
		city: string;
		streetLine1: string;
		streetLine2: string;
		streetLine3: string;
		houseNumber: string;
		zipCode: string;
	};
	companyPhoneNumber: string;
	companySize: string;
	companyWebsite: string;
	companyRegistrationYear: string;
	companyRevenue: string;
	companyNameError: boolean;
	companyAddressCityError: boolean;
	companyAddressStreetLine1Error: boolean;
	companyAddressStreeLine2Error: boolean;
	companyAddressStreeLine3Error: boolean;
	companyAddressHouseNumberError: boolean;
	companyAddressZipCodeError: boolean;
	companyPhoneError: boolean;
	companySizeError: boolean;
	companyWebsiteError: boolean;
	companyRegistrationError: boolean;
	companyRevenueError: boolean;
}

//--new combined props from state, own props, dispatch props -
//--as well as any props we want to pass - in a union type.
type IInsuranceOnBoardProps = PropsFromState &
	PropsFromDispatch &
	PropsForSelf &
	// RouteComponentProps<any> & //RouteComponentProps<{}>
	ConnectedReduxProps; //ConnectedReduxProps here is simply dispatch

// =======================
class InsuranceOnBoardDtl extends React.Component<
	IInsuranceOnBoardProps, //no props
	IInsuranceOnBoardState
> {
	constructor(props: any) {
		super(props);

		//initialize the state
		this.state = {
			companyName: "",
			companyAddress: {
				city: "",
				streetLine1: "",
				streetLine2: "",
				streetLine3: "",
				houseNumber: "",
				zipCode: ""
			},
			companyPhoneNumber: "",
			companySize: "",
			companyWebsite: "",
			companyRegistrationYear: "",
			companyRevenue: "",
			companyNameError: false,
			companyAddressCityError: false,
			companyAddressStreetLine1Error: false,
			companyAddressStreeLine2Error: false,
			companyAddressStreeLine3Error: false,
			companyAddressHouseNumberError: false,
			companyAddressZipCodeError: false,
			companyPhoneError: false,
			companySizeError: false,
			companyWebsiteError: false,
			companyRegistrationError: false,
			companyRevenueError: false
		};
	}

	componentDidMount() {}

	render() {
		return (
			<div>
				{/* {this.showHeaderPart()} */}

				<div className="insurance-onboard-container">
					<div className="header-div">
						<h3 className="major">Enroll to Buy Insurance</h3>
					</div>
					<form>
						<div className="company-name-div">
							<label>Company Name</label>
							<span style={{ color: "red" }}>*</span>
							<div className="error">{this.state.companyNameError ? <div>{"Name cannot be empty"}</div> : ""}</div>
							<input
								type="text"
								name="Your company name"
								placeholder="Your company name.."
								required
								onChange={this.onCompanyNameChange}
							></input>
							<br></br>
						</div>
						<div className="company-address-div">
							<label>Company Address</label>
							<div className="company-address-city-div">
								<label>City</label>
								<span style={{ color: "red" }}>*</span>
								<div className="error">
									{this.state.companyAddressCityError ? <div>{"City cannot be empty"}</div> : ""}
								</div>
								<input
									type="text"
									name="City"
									placeholder="City name.."
									required
									onChange={this.onCompanyAddressCityChange}
								></input>
							</div>
							<div className="company-address-streetline1-div">
								<label>Street Line 1</label>
								<span style={{ color: "red" }}>*</span>
								<div className="error">
									{this.state.companyAddressStreetLine1Error ? <div>{"Street Line 1 cannot be empty"}</div> : ""}
								</div>
								<input
									type="text"
									name="Street Line 1"
									placeholder="Street Line 1.."
									required
									onChange={this.onCompanyAddressStreetLine1Change}
								></input>
							</div>
							<div className="company-address-streetline2-div">
								<label>Street Line 2</label>
								{/* <span style={{ color: "red" }}>*</span> */}
								{/* <div className="error">
									{this.state.companyAddressStreeLine2Error ? <div>{"Street Line 2 cannot be empty"}</div> : ""}
								</div> */}
								<input
									type="text"
									name="Street Line 2"
									placeholder="Street Line 2.."
									required
									onChange={this.onCompanyAddressStreetLine2Change}
								></input>
							</div>
							<div className="company-address-streetline3-div">
								<label>Street Line 3</label>
								{/* <span style={{ color: "red" }}>*</span> */}
								{/* <div className="error">
									{this.state.companyAddressStreeLine3Error ? <div>{"Street Line 3 cannot be empty"}</div> : ""}
								</div> */}
								<input
									type="text"
									name="Street Line 3"
									placeholder="Street Line 3.."
									required
									onChange={this.onCompanyAddressStreetLine3Change}
								></input>
							</div>
							<div className="company-address-housenumber-div">
								<label>House Number</label>
								<span style={{ color: "red" }}>*</span>
								<div className="error">
									{this.state.companyAddressHouseNumberError ? <div>{"House Number cannot be empty"}</div> : ""}
								</div>
								<input
									type="text"
									name="House Number"
									placeholder="House Number.."
									required
									onChange={this.onCompanyAddressHouseNumberChange}
								></input>
							</div>
							<div className="company-address-zipcode-div">
								<label>Zip Code</label>
								<span style={{ color: "red" }}>*</span>
								<div className="error">
									{this.state.companyAddressZipCodeError ? <div>{"Zip Code cannot be empty"}</div> : ""}
								</div>
								<input
									type="text"
									name="Zip Code"
									placeholder="Zip Code.."
									required
									onChange={this.onCompanyAddressZipCodeChange}
								></input>
							</div>
							<br></br>
						</div>
						<div className="company-phone-div">
							<label>Company Phone Number</label>
							<span style={{ color: "red" }}>*</span>
							<div className="error">
								{this.state.companyPhoneError ? <div>{"Phone number cannot be empty or inproper USA number"}</div> : ""}
							</div>
							<input
								type="text"
								name="Your Company Phone Number"
								placeholder="Your Company Phone Number.."
								required
								onChange={this.onCompanyPhoneNumberChange}
							></input>
							<br></br>
						</div>

						<form className="company-size-div">
							<label>Company Size</label>
							<span style={{ color: "red" }}>*</span>
							<div className="error">
								{this.state.companySizeError ? <div>{"You must choose your company revenue range"}</div> : ""}
							</div>
							<label className="container">
								<input
									id="1"
									key={1}
									type="radio"
									checked={this.state.companySize === "< 10 people"}
									value="< 10 people"
									name="companySizeRadio"
									onClick={() => {
										this.setState({ companySize: "< 10 people", companySizeError: false });
									}}
									// onClick={this.onCompanySizeChange}
								/>
								{"< 10 people"}
								<span className="checkmark"></span>
							</label>
							<label className="container">
								<input
									id="2"
									key={1}
									type="radio"
									checked={this.state.companySize === "11-99 people"}
									value="11-99 people"
									name="companySizeRadio"
									onClick={() => {
										this.setState({ companySize: "11-99 people", companySizeError: false });
									}}
									// onClick={this.onCompanySizeChange}
								/>
								{"11-99 people"}
								<span className="checkmark"></span>
							</label>
							<label className="container">
								<input
									id="3"
									key={1}
									type="radio"
									checked={this.state.companySize === "100-999 people"}
									value="100-999 people"
									name="companySizeRadio"
									onClick={() => {
										this.setState({ companySize: "100-999 people", companySizeError: false });
									}}
									// onClick={this.onCompanySizeChange}
								/>
								{"100-999 people"}
								<span className="checkmark"></span>
							</label>
							<label className="container">
								<input
									id="4"
									key={1}
									type="radio"
									checked={this.state.companySize === ">= 1000 people"}
									value=">= 1000 people"
									name="companySizeRadio"
									onClick={() => {
										this.setState({ companySize: ">= 1000 people", companySizeError: false });
									}}
									// onClick={this.onCompanySizeChange}
								/>
								{">= 1000 people"}
								<span className="checkmark"></span>
							</label>
						</form>

						<div className="company-website-div">
							<label>Company Website</label>
							<span style={{ color: "red" }}>*</span>
							<div className="error">
								{this.state.companyWebsiteError ? <div>{"Website cannot be empty"}</div> : ""}
							</div>
							<input
								type="text"
								name="Your company website"
								placeholder="Your company website.."
								required
								onChange={this.onCompanyWebsiteChange}
							></input>
							<br></br>
						</div>
						<div className="company-registration-div">
							<label>Company Registration Year</label>
							<span style={{ color: "red" }}>*</span>
							<div className="error">
								{this.state.companyRegistrationError ? (
									<div>{"Company Registration Year cannot be empty or not a year number"}</div>
								) : (
									""
								)}
							</div>
							<input
								type="text"
								name="Your Company Registration Year"
								placeholder="Your Company Registration Year.."
								required
								onBlur={this.onCompanyRegistrationChange}
							></input>
							<br></br>
						</div>

						<div className="company-revenue-div">
							<label>Company Revenue Range</label>
							<span style={{ color: "red" }}>*</span>
							<div className="error">
								{this.state.companyRevenueError ? <div>{"You must choose your company revenue range"}</div> : ""}
							</div>
							<label className="container">
								<input
									id="5"
									key={2}
									type="radio"
									checked={this.state.companyRevenue === "< 1 Million USD"}
									value="< 1 Million USD"
									name="companyRevenueRadio"
									onClick={() => {
										this.setState({ companyRevenue: "< 1 Million USD", companyRevenueError: false });
									}}
									// onClick={this.onCompanyRevenueChange}
								/>
								{"< 1 Million USD"}
								<span className="checkmark"></span>
							</label>
							<label className="container">
								<input
									id="6"
									key={2}
									type="radio"
									checked={this.state.companyRevenue === "1-10 Million USD"}
									value="1-10 Million USD"
									name="companyRevenueRadio"
									onClick={() => {
										this.setState({ companyRevenue: "1-10 Million USD", companyRevenueError: false });
									}}
									// onClick={this.onCompanyRevenueChange}
								/>
								{"1-10 Million USD"}
								<span className="checkmark"></span>
							</label>
							<label className="container">
								<input
									id="7"
									key={2}
									type="radio"
									checked={this.state.companyRevenue === "> 10 Million USD"}
									value="> 10 Million USD"
									name="companyRevenueRadio"
									onClick={() => {
										this.setState({ companyRevenue: "> 10 Million USD", companyRevenueError: false });
									}}
									// onClick={this.onCompanyRevenueChange}
								/>
								{"> 10 Million USD"}
								<span className="checkmark"></span>
							</label>
						</div>

						<div>
							<input
								className="submit-button"
								disabled={
									!this.state.companyName ||
									!this.state.companyAddress ||
									!this.state.companyPhoneNumber ||
									!this.state.companySize ||
									!this.state.companyWebsite ||
									!this.state.companyRegistrationYear ||
									this.state.companyNameError ||
									this.state.companyAddressCityError ||
									this.state.companyAddressStreetLine1Error ||
									this.state.companyAddressZipCodeError ||
									this.state.companyPhoneError ||
									this.state.companySizeError ||
									this.state.companyWebsiteError ||
									this.state.companyRegistrationError ||
									this.state.companyRevenueError
								}
								type="submit"
								value="Submit"
								onClick={this.handleSubmitByRedux}
							></input>
							<br></br>
						</div>
					</form>
				</div>
			</div>
		);
	}

	@autobind
	private onCompanyNameChange(event: any) {
		event.preventDefault();
		if (!event.target.value.trim().length) {
			//String(event.target.value).length < 1
			this.setState({ companyNameError: true });
		} else {
			this.setState({ companyNameError: false });
			this.setState({ companyName: event.target.value });
		}
	}

	@autobind
	private onCompanyAddressCityChange(event: any) {
		event.preventDefault();
		if (!event.target.value.trim().length) {
			//String(event.target.value).length < 1
			this.setState({ companyAddressCityError: true });
		} else {
			this.setState({ companyAddressCityError: false });
			this.setState((prevState) => ({
				...prevState,
				companyAddress: { ...prevState.companyAddress, city: event.target.value }
			}));
		}
	}

	@autobind
	private onCompanyAddressStreetLine1Change(event: any) {
		event.preventDefault();
		if (!event.target.value.trim().length) {
			//String(event.target.value).length < 1
			this.setState({ companyAddressStreetLine1Error: true });
		} else {
			this.setState({ companyAddressStreetLine1Error: false });
			this.setState((prevState) => ({
				...prevState,
				companyAddress: { ...prevState.companyAddress, streetLine1: event.target.value }
			}));
		}
	}

	@autobind
	private onCompanyAddressStreetLine2Change(event: any) {
		event.preventDefault();
		if (event.target.value.trim().length > 0) {
			this.setState((prevState) => ({
				...prevState,
				companyAddress: { ...prevState.companyAddress, streetLine2: event.target.value }
			}));
		}
	}

	@autobind
	private onCompanyAddressStreetLine3Change(event: any) {
		event.preventDefault();
		if (event.target.value.trim().length > 0) {
			this.setState((prevState) => ({
				...prevState,
				companyAddress: { ...prevState.companyAddress, streetLine3: event.target.value }
			}));
		}
	}

	@autobind
	private onCompanyAddressHouseNumberChange(event: any) {
		event.preventDefault();
		if (!event.target.value.trim().length) {
			//String(event.target.value).length < 1
			this.setState({ companyAddressHouseNumberError: true });
		} else {
			this.setState({ companyAddressHouseNumberError: false });

			this.setState((prevState) => ({
				...prevState,
				companyAddress: { ...prevState.companyAddress, houseNumber: event.target.value }
			}));
		}
	}

	@autobind
	private onCompanyAddressZipCodeChange(event: any) {
		event.preventDefault();
		if (!event.target.value.trim().length) {
			//String(event.target.value).length < 1
			this.setState({ companyAddressZipCodeError: true });
		} else {
			this.setState({ companyAddressZipCodeError: false });
			this.setState((prevState) => ({
				...prevState,
				companyAddress: { ...prevState.companyAddress, zipCode: event.target.value }
			}));
		}
	}

	@autobind
	private onCompanyPhoneNumberChange(event: any) {
		event.preventDefault();
		if (!validator.isMobilePhone(String(event.target.value).toLowerCase(), "en-US")) {
			//String(event.target.value).length < 1
			this.setState({ companyPhoneError: true });
		} else {
			this.setState({ companyPhoneError: false });
			this.setState({ companyPhoneNumber: event.target.value });
		}
	}

	@autobind
	private onCompanyWebsiteChange(event: any) {
		event.preventDefault();
		if (!event.target.value.trim().length) {
			//String(event.target.value).length < 1
			this.setState({ companyWebsiteError: true });
		} else {
			this.setState({ companyWebsiteError: false, companyWebsite: event.target.value });
		}
	}

	@autobind
	private onCompanyRegistrationChange(event: any) {
		event.preventDefault();
		if (!this.yearValidation(String(event.target.value).toLowerCase())) {
			//String(event.target.value).length < 1
			this.setState({ companyRegistrationError: true });
		} else {
			this.setState({ companyRegistrationError: false, companyRegistrationYear: event.target.value });
		}
	}

	@autobind
	private yearValidation(year: string) {
		var text = /^[0-9]+$/;
		if (parseInt(year) !== 0) {
			if (year !== "" && !text.test(year)) {
				return false;
			}

			if (year.length !== 4) {
				return false;
			}
			var current_year = new Date().getFullYear();
			if (parseInt(year) < 1920 || parseInt(year) > current_year) {
				return false;
			}
			return true;
		}
	}

	@autobind
	private async handleSubmitByRedux(event: any) {
		event.preventDefault();
		if (this.state.companyName === "") {
			return;
		} else {
			//alert("Invalid company name!");
		}

		if (!validator.isMobilePhone(String(this.state.companyPhoneNumber).toLowerCase(), "en-US")) {
			alert("Invalid Company Phone Number!");
			return;
		}

		if (this.state.companySize === "") {
			return;
		} else {
			//alert("Invalid company size!");
		}

		if (this.state.companyAddress.streetLine1 === "") {
			return;
		} else {
			//alert("Invalid company size!");
		}

		if (this.state.companyAddress.city === "") {
			return;
		}

		if (this.state.companyAddress.zipCode === "") {
			return;
		}

		if (this.state.companyWebsite === "") {
			return;
		} else {
			//alert("Invalid company website!");
		}

		if (!this.yearValidation(String(this.state.companyRegistrationYear))) {
			alert("Invalid Company Registration Year!");
			return;
		}

		if (this.state.companyRevenue === "") {
			return;
		} else {
			//alert("Invalid company revenue!");
		}

		let { token } = this.props.match.params;
		if (!token) {
			return;
		}

		const input: any = {
			token: token,
			companyName: this.state.companyName,
			companyAddress: {
				city: this.state.companyAddress.city,
				street:
					this.state.companyAddress.streetLine1 +
					this.state.companyAddress.streetLine2 +
					this.state.companyAddress.streetLine3,
				houseNumber: this.state.companyAddress.houseNumber,
				zipCode: this.state.companyAddress.zipCode
			},
			companyPhoneNumber: this.state.companyPhoneNumber,
			companySize: this.state.companySize,
			companyWebsite: this.state.companyWebsite,
			companyRegistrationYear: this.state.companyRegistrationYear,
			companyRevenue: this.state.companyRevenue
		};

		this.props.submitInsuranceOnBoard(input, this.showSuccessAfterSubmit);
	}

	showSuccessAfterSubmit = () => {
		alert("good news, your company information has been submitted.");
		setTimeout(() => {
			// window.location.href = "/index.html";
			window.location.href = "/";
		}, 3000);
		//here we should update the state using
	};
}

//map props to state, such that if store.state.posts changes. the partialPosts as a prop
//here will also change, once it is changed, the view will be re-rendered

//--new map store state to props
const mapStateToProps = ({ insurancebuyer }: ApplicationState) => {
	return {
		//here ApplicationState.post is taken and mapped to partialPosts
		id: insurancebuyer.id,
		companyName: insurancebuyer.companyName,
		companyPhoneNumber: insurancebuyer.companyPhoneNumber,
		companySize: insurancebuyer.companySize,
		companyWebsite: insurancebuyer.companyWebsite,
		companyRegistrationYear: insurancebuyer.companyRegistrationYear,
		companyRevenue: insurancebuyer.companyRevenue,
		email: insurancebuyer.email,
		name: insurancebuyer.name
		// insurancepolicies:insurancebuyer.insurancepolicies
	};
};

// const mapDispatchToProps = (dispatch: Dispatch) => {
//   return {
//     //return an object that makes the getsearchPosts etc props as actions to the store
//     getSearchPosts: (input: string) => {
//       dispatch(getSearchPosts(input)); //dispatches the getSearchPosts defined in ActionCreator.tsx
//     },
//   };
// };

//map dispatch to props  especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = {
	submitInsuranceOnBoard: submitInsuranceOnBoard
};

//connect is used to create a component and get tthe state from the redux store
//and pass the data as props to the component

// Wrapping the main class with this functional component
function InsuranceOnBoard(props: any) {
	let navigate = useNavigate();
	const params = useParams();
	return <InsuranceOnBoardDtl {...{ ...props, match: { params } }} navigate={navigate} />;
}

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InsuranceOnBoard) as any);

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceOnBoard);
