//actions here define the type and the action for each Type identified

// This type is basically shorthand for `{ [key: string]: any }`. Feel free to replace `any` with
// the expected return type of your API response.
//export type ApiResponse = Record<string, any>;

export const enum NewsLetterActionTypes {
	FETCH_NEWSLETTERS = "@@newsletters/FETCH_NEWSLETTERS",
	FETCH_NEWSLETTERS_SUCCESS = "@@newsletters/FETCH_NEWSLETTERS_SUCCESS",
	FETCH_NEWSLETTERS_ERROR = "@@newsletters/FETCH_NEWSLETTERS_ERROR",

	CREATE_NEWSLETTER = "@@newsletters/CREATE_NEWSLETTER",
	CREATE_NEWSLETTER_SUCCESS = "@@newsletters/CREATE_NEWSLETTER_SUCCESS",
	CREATE_NEWSLETTER_ERROR = "@@newsletters/CREATE_NEWSLETTER_ERROR"
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability

// These are going to be in the redux state that the PostsReducer will modify
// upon dispatch with the associated data

export interface NewsLetterState {
	//here we should use real type rather than any***
	//readonly loading: boolean;
	//readonly data: any[];
	//readonly errors?: string;
	readonly newsletters: any[];
}
