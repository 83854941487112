import {
	all,
	call,
	fork,
	put,
	//  select,
	takeEvery,
	takeLatest
} from "redux-saga/effects";
import { InsurancePolicyActionTypes } from "./InsurancePolicyTypes";
import axios from "axios";
// import { getRecentSearches } from "./SearchesActionsCreatorSaga";
import {
	fetchInsurancePoliciesSucess,
	fetchInsurancePoliciesError,
	createInsurancePolicySuccess,
	createInsurancePolicyError,
	updateInsurancePolicySuccess,
	updateInsurancePolicyError
} from "./InsurancePolicyActionsSaga";

//GENERATOR functions are coded here to deal with async processes such as fetch data, and then it will call the
//fucntions in PostsActionsCreateSaga.tsx to do the actual dispatch to update the store.

//--FETCH_INSURANCEBUYERS
function fecthInsurancePoliciesApi(value: string) {
	//const API_ENDPOINT =
	//  process.env.REACT_APP_API_ENDPOINT || "https://api.opendota.com";
	//function that returns an axios call
	const API_ENDPOINT = "/api/insurance/getInsuranceBuyers";

	return axios.get(API_ENDPOINT, {
		params: {
			//value should be the email or name to be searched in database
			value
		}
	});
}
/** saga worker that is responsible for the side effects */
function* fecthInsurancePoliciesSaga(action: any) {
	//GENERATOR function* that calls the fetchPostApi with the argument provided
	try {
		//data is obtained after axios call is resolved
		//after fetch, if success, data should contain the insurancebuyers returned from back-end

		let { data } = yield call(fecthInsurancePoliciesApi, action.payload.input);
		//you may want to store response to localStorage, or not.
		//here is where the dispatch happens,and the data obtained will be used to update the redux-store
		if (data.success === true && data.result.insurancepolicies) {
			let myOptions: any = [];
			const insurancepolicies = data.result.insurancebuyers;
			if (insurancepolicies.length > 0) {
				insurancepolicies.map((insurancepolicy: any) => myOptions.push(insurancepolicy));
			} else {
				myOptions = [];
			}
			yield put(fetchInsurancePoliciesSucess(myOptions));
			//invoke the callBack function in action if needed
		} else {
			const error = new Error("error fetching recent searches");
			throw error;
		}
	} catch (error) {
		yield put(fetchInsurancePoliciesError(error));
	}
}
// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `fecthPostsSaga()` saga above.
function* watchFetchInsurancePolicy() {
	yield takeLatest(InsurancePolicyActionTypes.FETCH_INSURANCEPOLICIES, fecthInsurancePoliciesSaga);
}

//---Create Insurance Policy
function createInsurancePolicyApi(
	//here should include name, email and preferredLanguage  to submit to back-end
	input: {
		insurance_buyer_id: string;
		email: string;
		commodity_name: string;
		invoice_number: string;
		shipment_carrier: string;
		shipment_vessel: string;
		shipment_voyage: string;
		cargo_value: number;
		cargo_quantity: number;
		cargo_weight: number;
		cargo_number_of_containers: number;
		insured_value: number;
		beneficiary: string;
		port_of_discharge: string;
		port_of_loading: string;
		ETD: string;
		ETA: string;
		currency_of_claim: string;
		location_of_claim_payment: string;
		method_of_claim_payment: string;
		policy_choice: string[];
	}
	//csrfToken: string
) {
	//const API_ENDPOINT =
	//  process.env.REACT_APP_API_ENDPOINT || "https://api.opendota.com";
	//function that returns an axios call
	const API_ENDPOINT = "/api/insurance/createInsurancePolicy"; //follow the router
	return axios.post(API_ENDPOINT, input, {
		headers: {
			//"X-CSRF-Token": csrfToken
		}
	});
}

/** saga worker that is responsible for the side effects */
function* createInsurancePolicySaga(action: any): any {
	//GENERATOR function* that calls the fetchPostApi with the argument provided
	try {
		//let csrfTokenNew = yield call(fetchCSRFToken);
		//data is obtained after axios callis resolved
		let { data } = yield call(
			createInsurancePolicyApi, //This is the API that goes to the back-end
			action.payload.input
			//csrfToken
		);
		//You may want to store data to localStorage, or not.
		//Here is where the dispatch happens,and the posts data obtained
		//will be used to update the redux-store
		if (data.success === true && data.result) {
			yield put(createInsurancePolicySuccess(data.result.insurancePolicy));
			//call action in reducer to update redux state
			yield call(action.meta.callbackAction, data.result.insurancePolicy._id);
			//and then call the meta function if needed and update the local state there
		} else {
			throw new Error("Error creating insurance policy");
		}
	} catch (error) {
		yield put(createInsurancePolicyError(error));
		//call the action to handle the error in state
		//some times no thing is done at all
	}
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `submitPostInfoSaga()` saga above.
function* watchCreateInsurancePolicy() {
	yield takeEvery(InsurancePolicyActionTypes.CREATE_INSURANCEPOLICY, createInsurancePolicySaga);
}

//---Update Insurance Policy
function updateInsurancePolicyApi(
	//here should include name, email and preferredLanguage  to submit to back-end
	input: {
		id: string; //for update we must already have the policy id
		insurance_buyer_id: string;
		email: string;
		commodity_name: string;
		shipment_carrier: string;
		shipment_vessel: string;
		shipment_voyage: string;
		cargo_value: number;
		cargo_quantity: number;
		cargo_weight: number;
		cargo_number_of_containers: number;
		insured_value: number;
		beneficiary: string;
		port_of_discharge: string;
		port_of_loading: string;
		ETD: string;
		ETA: string;
		currency_of_claim: string;
		location_of_claim_payment: string;
		method_of_claim_payment: string;
		policy_choice: string[];
	}
	//csrfToken: string
) {
	//const API_ENDPOINT =
	//  process.env.REACT_APP_API_ENDPOINT || "https://api.opendota.com";
	//function that returns an axios call
	const API_ENDPOINT = "/api/insurance/updateInsurancePolicy"; //follow the router
	return axios.post(API_ENDPOINT, input, {
		headers: {
			//"X-CSRF-Token": csrfToken
		}
	});
}

/** saga worker that is responsible for the side effects */
function* updateInsurancePolicySaga(action: any): any {
	//GENERATOR function* that calls the fetchPostApi with the argument provided
	try {
		//let csrfTokenNew = yield call(fetchCSRFToken);
		//data is obtained after axios callis resolved
		let { data } = yield call(
			updateInsurancePolicyApi, //This is the API that goes to the back-end
			action.payload.input
			//csrfToken
		);
		//You may want to store data to localStorage, or not.
		//Here is where the dispatch happens,and the posts data obtained
		//will be used to update the redux-store
		if (data.success === true && data.result) {
			yield put(updateInsurancePolicySuccess(data.result.insurancePolicy)); //call action in reducer to update state
			yield call(action.meta.callbackAction); //and then call the meta function if needed
		} else {
			throw new Error("Error updating insurance policy");
		}
	} catch (error) {
		yield put(updateInsurancePolicyError(error));
		//call the action to handle the error in state
		//some times no thing is done at all
	}
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `submitPostInfoSaga()` saga above.
function* watchUpdateInsurancePolicy() {
	yield takeEvery(InsurancePolicyActionTypes.UPDATE_INSURANCEPOLICY, updateInsurancePolicySaga);
}

//put all above sagas for posts together into postsSaga and export it
// We can also use `fork()` here to split our saga into multiple watchers.
function* insurancepolicySaga() {
	yield all([
		fork(watchFetchInsurancePolicy),
		fork(watchCreateInsurancePolicy),
		fork(watchUpdateInsurancePolicy)
		//,Add other watchers here to the list for handling posts related actions
	]);
}
export default insurancepolicySaga;
