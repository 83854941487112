import { InsuranceBuyerActionTypes } from "./InsuranceBuyerTypes";

//below are functions that take an input and then return a plain action method
export function submitInsuranceEnrollment(input: any, callback: any) {
	return {
		//return an action
		type: InsuranceBuyerActionTypes.CREATE_INSURANCEBUYER,
		payload: { input: input }, //input is data to submit (email, name, preferredLanguage)
		meta: { callbackAction: callback }
		//meta: extra function you want to do after the state is changed
	};
}

export function submitInsuranceOnBoard(input: any, callback: any) {
	return {
		//return an action
		type: InsuranceBuyerActionTypes.UPDATE_INSURANCEBUYER,
		payload: { input: input }, //input is data to submit (email, name, preferredLanguage)
		meta: { callbackAction: callback }
		//meta: extra function you want to do after the state is changed
	};
}

export function getInsuranceBuyers(input: any) {
	return {
		type: InsuranceBuyerActionTypes.FETCH_INSURANCEBUYERS,
		payload: { input: input }
	};
}

export function submitEmailCode(input: any, onSuccess: any, onFailure: any) {
	return {
		type: InsuranceBuyerActionTypes.SUBMIT_EMAILCODE,
		payload: { input: input },
		meta: { callbackActionOnSuccess: onSuccess, callbackActionOnError: onFailure }
	};
}

export function submitRequestEmailCode(input: any, onSuccess: any, onFailure: any) {
	return {
		type: InsuranceBuyerActionTypes.REQUEST_EMAILCODE,
		payload: { input: input },
		meta: { callbackActionOnSuccess: onSuccess, callbackActionOnError: onFailure }
	};
}

export function submitUserEmailPasswordInfo(input: any, onSuccess: any, onFailure: any) {
	return {
		type: InsuranceBuyerActionTypes.SUBMIT_EMAILPASSWORDINFO,
		payload: { input: input },
		meta: { callbackActionOnSuccess: onSuccess, callbackActionOnError: onFailure }
	};
}

//new registration
export function submitUserRegistrationInfo(input: any, onSuccess: any, onFailure: any) {
	return {
		//return an action
		type: InsuranceBuyerActionTypes.CREATE_INSURANCEBUYER,
		payload: { input: input }, //input is data to submit (email, name, preferredLanguage)
		meta: { callbackActionOnSuccess: onSuccess, callbackActionOnError: onFailure }
		//meta: extra function you want to do after the state is changed
	};
}

//check if phone already registered
export function checkPhoneAvailability(input: any, onSuccess: any, onFailure: any) {
	return {
		//return an action
		type: InsuranceBuyerActionTypes.CHECK_PHONEAVAILABLITY,
		payload: { input: input }, //input is data to submit
		meta: { callbackActionOnSuccess: onSuccess, callbackActionOnError: onFailure }
		//meta: extra function you want to do after the state is changed
	};
}

//check if email already registered
export function checkEmailAvailability(input: any, onSuccess: any, onFailure: any) {
	return {
		//return an action
		type: InsuranceBuyerActionTypes.CHECK_EMAILAVAILABLITY,
		payload: { input: input }, //input is data to submit
		meta: { callbackActionOnSuccess: onSuccess, callbackActionOnError: onFailure }
		//meta: extra function you want to do after the state is changed
	};
}

//send pin code by SMS
export function sendVerificationCodeBySMS(input: any, onSuccess: any, onFailure: any) {
	return {
		//return an action
		type: InsuranceBuyerActionTypes.SEND_CODEBYSMS,
		payload: { input: input }, //input is data to submit
		meta: { callbackActionOnSuccess: onSuccess, callbackActionOnError: onFailure }
		//meta: extra function you want to do after the state is changed
	};
}

//verify if pin code is correct by submitting code
export function checkVerificationCode(input: any, onSuccess: any, onFailure: any) {
	return {
		//return an action
		type: InsuranceBuyerActionTypes.VERIFY_CODE,
		payload: { input: input },
		meta: { callbackActionOnSuccess: onSuccess, callbackActionOnError: onFailure }
		//meta: extra function you want to do after the state is changed
	};
}
