import * as React from "react";
import "./PageNotFound.scss";

// props from redux store state (we will use the redux store state as if it was
// a props of this class component)

//-- new own props
interface PropsForSelf {}

interface IState {}

//--new combined props from state, own props, dispatch props -
//--as well as any props we want to pass - in a union type.
type IProps = PropsForSelf;

// =======================
class PageNotFound extends React.Component<
	IProps, //no props
	IState
> {
	constructor(props: any) {
		super(props);

		//initialize the state
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<div className="page-not-found-page">
				{/* {this.showHeaderPart()} */}
				<div className="page-not-found-container">404: Page not found.</div>
			</div>
		);
	}
}

export default PageNotFound;

// export function PageNotFound() {
//     return (
//       <div>
//         <h2>404 Page not found</h2>
//       </div>
//     );
//   }
