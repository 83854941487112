// import logo from './logo.svg';
import "./App.scss";
import NewsLetterCompose from "./components/NewsLetterCompose";
import NewsLetterSubscription from "./components/NewsLetterSubscribe";
import ContactUsPage from "./components/ContactUsPage";
import PageNotFound from "./components/PageNotFound"; //404 handling

import { Provider } from "react-redux";
import { Route, BrowserRouter, Routes } from "react-router-dom";

//---new store combined from multiple stores and multiple reducers ---
import { createBrowserHistory } from "history";
import configureStore from "./configureStore";
import { ApplicationState } from "./store";
import HomePage from "./HomePage";
import InsuranceEnrollment from "./components/InsuranceEnrollment";
import InsuranceOnBoard from "./components/InsuranceOnBoard";
import InsurancePurchase from "./components/InsurancePurchase";
import CareerPage from "./components/CareerPage";
import PolicyApplication from "./components/policyApplication/PolicyApplication";

function App() {
	const history = createBrowserHistory();
	//const initialState = window.initialReduxState;

	type ApplicationStateType = ApplicationState;
	const initialState: ApplicationStateType = (window as any).initialReduxState;
	const store = configureStore(history, initialState);

	return (
		<div>
			<Provider store={store}>
				{showHeader()}
				{/* <Provider store={store}> */}
				<BrowserRouter>
					<Routes>
						{/* Otherrs say not found */}
						<Route path="*" element={<PageNotFound />} />

						{/* These two are the same */}
						<Route path="/" element={<HomePage />} />
						<Route path="/index.html" element={<HomePage />} />

						{/*Point to contactus  */}
						<Route path="/contactus" element={<ContactUsPage />} />

						{/* Point to newsletter subscription */}
						<Route path="/newsletter/subscribe" element={<NewsLetterSubscription />} />

						<Route path="/newsletter/compose" element={<NewsLetterCompose />} />

						{/* Point to insurance subscription */}
						<Route path="/insurance/enroll" element={<InsuranceEnrollment />} />

						<Route path="/insurance/onboard/:token" element={<InsuranceOnBoard />} />

						<Route path="/insurance/purchase" element={<InsurancePurchase />} />

						<Route path="/insurance/policyapplication" element={<PolicyApplication />} />

						{/* Point to career  */}
						<Route path="/career" element={<CareerPage />} />
					</Routes>
				</BrowserRouter>
				{showCopyRight()}
			</Provider>
		</div>
	);
}

const showHeader = () => {
	return (
		<>
			{/* <!-- Header --> */}
			<div id="header">
				<div className="container">
					{/* <!-- Logo --> */}
					<h1>
						<a href="/#" id="logo">
							Haymingway LLC
						</a>
					</h1>
					{/* <!-- Nav --> */}
					<nav id="nav">
						<ul>
							<li>
								{/* <a href="/index.html">Home</a> */}
								<a href="/">Home</a>
							</li>
							<li>
								<a href="/">Hay Sourcing</a>
								<ul>
									<li>
										<a href="/AlfalfaCultivation.html">Alfalfa Cultivation</a>
									</li>
									<li>
										<a href="/AlfalfaAcquisition.html">Alfalfa Acquisition</a>
									</li>
									<li>
										<a href="/AlfalfaProcessing.html">Alfalfa Processing</a>
									</li>
								</ul>
							</li>
							<li>
								<a href="/ExportToChina.html">Hay Export</a>
							</li>
							<li>
								<a href="/ShippingToChina.html">Shipping</a>
							</li>

							{/* <li>
								<a href="/insurancetemplate/file4.html/file4.html">html4</a>
							</li>
							<li>
								<a href="/insurancetemplate/file3.html/file3.html">html3</a>
							</li> */}

							{/* <li>
								<a href="/NewsLetterSubscribe.html">Subscribe to Newsletter Old</a>
							</li> */}
							<li>
								<a href="/newsletter/subscribe">Subscribe to Newsletter</a>
							</li>

							{/*
							<li>
								<a href="/">Buy Ocean Freight Insurance</a>
								<ul>
									<li>
										<a href="/insurance/enroll">Enroll to Buy Insurance</a>
									</li>
									<li>
										<a href="/insurance/login">Login</a>
									</li>
									<li>
										<a href="/insurance/purchase">Buy Insurance</a>
									</li>
									<li>
										<a href="/insurance/policyapplication">Policy Application </a>
									</li>
								</ul>
							</li> */}

							{/* <!-- <li><a href="ContactUs.html">Contact Us</a></li> --> */}
							<li>
								<a href="/contactus">Contact Us</a>
							</li>
							<li>
								<a href="/career">Career</a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</>
	);
};

const showCopyRight = () => {
	return (
		<>
			<div className="copyright">
				<p>
					{" "}
					&copy;
					<script>document.write(new Date().getFullYear())</script> Haymingway
				</p>
				<p> Address: 1326 N Brook Court, Ellensburg, WA, 98926</p>
			</div>
		</>
	);
};

//--
export default App;
