import * as React from "react";
import autobind from "autobind-decorator";
import validator from "validator";
import "./InsurancePurchase.scss";
import Axios from "axios";
import {
	createInsurancePolicy,
	updateInsurancePolicy
	// checkInsuranceBuyerEmail
} from "../store/InsurancePolicy/InsurancePolicyActionsCreatorSaga";

import { useParams } from "react-router-dom";
//connect allows a component to use redux store as props of the component
import { connect } from "react-redux";
//react router allows one to navigate from within the component to other components
//import { RouteComponentProps } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//
import { ApplicationState, ConnectedReduxProps } from "../store";
// props from redux store state (we will use the redux store state as if it was
// a props of this class component)
import FilesUploader from "./FilesUploader";

interface PropsFromState {
	//here is what we use from redux-store//state//InsuranceBuyerState
	// email: string;
	// name: string;
	// insurancepolicies: any[];
	//id: string;
	insurancePolicy: any;
}

//-- new own props
interface PropsForSelf {
	match: {
		params: any;
	};
}

interface PropsFromDispatch {
	//allow uss to use functions from redux-actions as if they were
	//props of this class component
	createInsurancePolicy: typeof createInsurancePolicy;
	updateInsurancePolicy: typeof updateInsurancePolicy;
	// checkInsuranceBuyerEmail: typeof checkInsuranceBuyerEmail;
}

interface IInsurancePurchaseState {
	email: string;
	emailError: boolean;
	emailErrorMessage: string;
	previewReady: boolean;
	approved: boolean;
	choice1selected: boolean;
	choice2selected: boolean;
	choice3selected: boolean;
	id: string;
	commodity_name: string;
	invoice_number: string;
	invoice_number_hint: string;
	shipment_carrier: string;
	shipment_vessel: string;
	shipment_voyage: string;
	cargo_value: number;
	cargo_value_hint: string;
	cargo_quantity: number;
	cargo_quantity_hint: string;
	cargo_weight: number;
	cargo_weight_hint: string;
	cargo_number_of_containers: number;
	cargo_number_of_containers_hint: string;
	insured_value: number;
	beneficiary: string;
	port_of_discharge: string;
	port_of_loading: string;
	ETD: string;
	ETA: string;
	currency_of_claim: string;
	location_of_claim_payment: string;
	method_of_claim_payment: string;
	policy_choice: string[];
	commodity_name_error: boolean;
	invoice_number_error: boolean;
	shipment_carrier_error: boolean;
	shipment_vessel_error: boolean;
	shipment_voyage_error: boolean;
	cargo_value_error: boolean;
	cargo_quantity_error: boolean;
	cargo_weight_error: boolean;
	cargo_number_of_containers_error: boolean;
	insured_value_error: boolean;
	beneficiary_error: boolean;
	port_of_discharge_error: boolean;
	port_of_loading_error: boolean;
	ETD_error: boolean;
	ETA_error: boolean;
	currency_of_claim_error: boolean;
	location_of_claim_payment_error: boolean;
	method_of_claim_payment_error: boolean;
	policy_choice_error: boolean;

	filePaths: string[];
	uploaded: boolean; //whether invoice/PL uploaded
	ingested: boolean; //where invoice/PL ingested
	retrieved: boolean; //where invoice/PL data retrieved

	emailExists: boolean; //check if the buyer already registered/enrolled by Email
	purchaseStep: number; //step progress in the purchase:
	//1: start (enter email/phone)
	//2: application form auto filled
	//3：application form prereview
	//4: payment
	//5: application submitted
	insurance_buyer_id: string;
	textKey: string;

	username: string;
}

//--new combined props from state, own props, dispatch props -
//--as well as any props we want to pass - in a union type.
type IInsurancePurchaseProps = PropsFromState &
	PropsFromDispatch &
	PropsForSelf &
	// RouteComponentProps<any> & //RouteComponentProps<{}>
	ConnectedReduxProps; //ConnectedReduxProps here is simply dispatch

class InsurancePurchaseDtl extends React.Component<
	IInsurancePurchaseProps, //no props
	IInsurancePurchaseState
> {
	_isMounted = false;
	constructor(props: any) {
		super(props);
		this.state = {
			email: "",
			emailError: false,
			emailErrorMessage: "",
			previewReady: true,
			approved: false,
			choice1selected: false,
			choice2selected: false,
			choice3selected: false,
			id: "",
			commodity_name: "",
			invoice_number: "",
			invoice_number_hint: "",
			shipment_carrier: "",
			shipment_vessel: "",
			shipment_voyage: "",
			cargo_value: 0,
			cargo_value_hint: "",
			cargo_quantity: 0,
			cargo_quantity_hint: "",
			cargo_weight: 0,
			cargo_weight_hint: "",
			cargo_number_of_containers: 0,
			cargo_number_of_containers_hint: "",
			insured_value: 0,
			beneficiary: "",
			port_of_discharge: "",
			port_of_loading: "",
			ETD: "",
			ETA: "",
			currency_of_claim: "",
			location_of_claim_payment: "",
			method_of_claim_payment: "",
			policy_choice: [],

			commodity_name_error: false,
			invoice_number_error: false,
			shipment_carrier_error: false,
			shipment_vessel_error: false,
			shipment_voyage_error: false,
			cargo_value_error: false,
			cargo_quantity_error: false,
			cargo_weight_error: false,
			cargo_number_of_containers_error: false,
			insured_value_error: false,
			beneficiary_error: false,
			port_of_discharge_error: false,
			port_of_loading_error: false,
			ETD_error: false,
			ETA_error: false,
			currency_of_claim_error: false,
			location_of_claim_payment_error: false,
			method_of_claim_payment_error: false,
			policy_choice_error: false,

			filePaths: [], //["/1688087148235_untitled_image.idf", "1688088331105_untitled_image.idf"], //e.g  ["/1687223358675_rvecs.pdf"]
			uploaded: false,
			ingested: false,
			retrieved: false,

			emailExists: false,
			purchaseStep: 1, //by default the page is at 1
			insurance_buyer_id: "",
			textKey: "",

			username: ""
		};
	}

	componentDidMount() {
		this._isMounted = true;
		// if (!this.state.isPinSent) {
		// this.sendPin();
		//this should be only done once, however it does not work
		//here with conditional rending in this component
		//we may have to pass this function to <PinEntry /> component instead
		// }

		//get the params and set the step if need to

		const params = new URLSearchParams(window.location.search);
		const step: string = params.get("step") || "";
		const stepInt = parseInt(step);
		if (stepInt >= 2 && stepInt <= 5) {
			this.setState({
				purchaseStep: stepInt
			});
		} else {
			this.setState({
				purchaseStep: 1
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		return (
			<div>
				<div className="container">
					<div className="header-div">
						<h3 className="major">Insurance Application</h3>
					</div>
					{this.state.purchaseStep == 1 && (
						<div className="step1-email-entry">
							<div>
								<h5>Step 1: Please enter your email address to start an appliction</h5>
							</div>
							<div className="email-entry-button">
								<div className="email-div">
									<label className="email-label">
										Email
										<span className="error">{this.state.emailError ? "*" : ""}</span>
									</label>
									<input
										className="email-input"
										type="text"
										name="email"
										placeholder="yourname@yourcompany.com"
										required
										onChange={this.onEmailChange}
									></input>
								</div>
								<button className="entry-button" onClick={this.onEmailSubmit}>
									Submit Email
								</button>
							</div>
						</div>
					)}

					{this.state.purchaseStep == 2 && (
						<div>
							<div>
								<h5>
									Step 2: Please upload your shipment invoice and packing list, we will try to fill out the following
									application form for you.
								</h5>
							</div>
							<div className="user-name-div">
								<label>User Name</label>
								<span style={{ color: "red" }}>*</span>
								<label className="container">
									<input
										type="radio"
										checked={this.state.username === "stevens"}
										name="radio"
										onClick={() => {
											this.setState({ username: "stevens" });
										}}
									/>
									Stevens
									<span className="checkmark"></span>
								</label>
								<label className="container">
									<input
										type="radio"
										checked={this.state.username === "whitby"}
										name="radio"
										onClick={() => {
											this.setState({ username: "whitby" });
										}}
									/>
									Whitby
									<span className="checkmark"></span>
								</label>
								<label className="container">
									<input
										type="radio"
										checked={this.state.username === "bluemountain"}
										name="radio"
										onClick={() => {
											this.setState({ username: "bluemountain" });
										}}
									/>
									BlueMountain
									<span className="checkmark"></span>
								</label>
								<label className="container">
									<input
										type="radio"
										checked={this.state.username === "stredwick"}
										name="radio"
										onClick={() => {
											this.setState({ username: "stredwick" });
										}}
									/>
									Stredwick
									<span className="checkmark"></span>
								</label>
								<label className="container">
									<input
										type="radio"
										checked={this.state.username === "hajny"}
										name="radio"
										onClick={() => {
											this.setState({ username: "hajny" });
										}}
									/>
									Hajny
									<span className="checkmark"></span>
								</label>
							</div>
							<form>
								<FilesUploader
									showListOfFiles={false}
									showMessage={false} //
									showProgress={true}
									fileTypesAllowed={["pdf", "idf"]} //only allow pdf files
									onFilesUpdated={this.onFilesUpdated}
								></FilesUploader>
								{this.state.filePaths.length >= 1 && (
									<div className="submit-div">
										<input
											type="submit"
											disabled={!this.state.uploaded}
											// disabled={!this.state.uploaded}
											value="Ingest Data" //here we ingest data
											onClick={this.handleIngestData}
										></input>
									</div>
								)}
								{this.state.filePaths.length >= 1 && //
									this.state.ingested && //
									this.state.textKey && (
										<div className="submit-div">
											<input
												type="submit"
												disabled={!this.state.ingested}
												value="Get Data" //here we get data from uploaded file
												onClick={this.handleGetInsuranceDataFromDocs}
											></input>
										</div>
									)}
								<div className="commodity-name-div">
									<div className="commodity-name">
										<label>Commodity Name</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.commodity_name_error ? <div>{"Commodity name cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your commodity name is..."
											required
											value={this.state.commodity_name}
											onChange={this.onCommodityNameChange}
										></input>
										<br></br>
									</div>
									<div className="commodity-text-hints">
										<label>text hints in docs</label>
										{/* <span style={{ color: "red" }}>*</span> */}
										<div className="error">
											{/* {this.state.commodity_name_error ? <div>{"Commodity name cannot be empty"}</div> : ""} */}
										</div>
										<input
											type="text"
											name="commodity text hints"
											placeholder="texts that identify commodity in your docs, e.g. Commodity Name"
											// required
											// value={this.state.commodity_name}
											onChange={this.onCommodityNameHintsChange}
										></input>
										<br></br>
									</div>
									<div className="update-button-div">
										<div
											className="btn-primary"
											onClick={() => {
												alert("update button clicked");
											}}
										>
											Update
										</div>
									</div>
								</div>
								<div className="invoice-number-div">
									<div className="invoice-number">
										<label>Invoice Number</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.invoice_number_error ? <div>{"Invoice number cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your invoice number is..."
											required
											value={this.state.invoice_number}
											onChange={this.onInvoiceNumberChange}
										></input>
										<br></br>
									</div>
									<div className="invoice-number-text-hints">
										<label>text hints in docs</label>
										{/* <span style={{ color: "red" }}>*</span> */}
										<div className="error">
											{/* {this.state.invoice_number_error ? <div>{"Invoice number hint cannot be empty"}</div> : ""} */}
										</div>
										<input
											type="text"
											name="invoice number text hints"
											placeholder="texts that identify invoice number in your docs, e.g. Invoice No."
											// required
											value={this.state.invoice_number}
											onChange={this.onInvoiceNumberHintsChange}
										></input>
										<br></br>
									</div>
									<div className="update-button-div">
										<div
											className="btn-primary"
											onClick={() => {
												alert("update button clicked");
											}}
										>
											Update
										</div>
									</div>
								</div>

								<div className="shipment-carrier-div">
									<div className="shipment-carrier">
										<label>Shipment Carrier</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.shipment_carrier_error ? <div>{"Shipment carrier name cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your shipment carrier name is..."
											required
											value={this.state.shipment_carrier}
											onChange={this.onShipmentCarrierChange}
										></input>
										<br></br>
									</div>
									<div className="shipment-carrier-text-hints">
										<label>text hints in docs</label>
										<div className="error"></div>
										<input
											type="text"
											name="shipment carrier text hints"
											placeholder="shipment carrier, e.g. Carrier"
											// required
											// value={this.state.commodity_name}
											onChange={this.onShipmentCarrierHintsChange}
										></input>
										<br></br>
									</div>
								</div>
								<div className="shipment-vessel-div">
									<div className="shipment-vessel">
										<label>Shipment Vessel</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.shipment_vessel_error ? <div>{"Shipment vessel name cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your shipment vessel name is..."
											required
											value={this.state.shipment_vessel}
											onChange={this.onShipmentVesselChange}
										></input>
										<br></br>
									</div>
									<div className="shipment-vessel-text-hints">
										<label>text hints in docs</label>
										<div className="error"></div>
										<input
											type="text"
											name="shipment vessel text hints"
											placeholder="shipment vessel, e.g. Vessel"
											// required
											// value={this.state.commodity_name}
											onChange={this.onShipmentVesselHintsChange}
										></input>
										<br></br>
									</div>
								</div>
								<div className="shipment-voyage-div">
									<div className="shipment-voyage">
										<label>Shipment Voyage</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.shipment_voyage_error ? <div>{"Shipment voyage cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your shipment voyage is..."
											required
											value={this.state.shipment_voyage}
											onChange={this.onShipmentVoyageChange}
										></input>
										<br></br>
									</div>
									<div className="shipment-voyage-text-hints">
										<label>text hints in docs</label>
										<div className="error"></div>
										<input
											type="text"
											name="shipment voyage text hints"
											placeholder="shipment voyage, e.g. Voyage"
											// required
											// value={this.state.commodity_name}
											onChange={this.onShipmentVoyageHintsChange}
										></input>
										<br></br>
									</div>
								</div>
								<div className="cargo-value-div">
									<div className="cargo-value">
										<label>Cargo Value</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.cargo_value_error ? <div>{"Cargo value cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your cargo value is..."
											required
											value={this.state.cargo_value}
											onChange={this.onCargoValueChange}
										></input>
										<br></br>
									</div>
									<div className="cargo-value-text-hints">
										<label>text hints in docs</label>
										<div className="error"></div>
										<input
											type="text"
											name="cargo value text hints"
											placeholder="cargo total value, e.g. total amount"
											// required
											// value={this.state.commodity_name}
											onChange={this.onCargoValueHintsChange}
										></input>
										<button onClick={this.updateCargoValue}>Update</button>
										<br></br>
									</div>
								</div>
								<div className="cargo-quantity-div">
									<div className="cargo-quantity">
										<label>Cargo Quantity</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.cargo_quantity_error ? <div>{"Cargo quantity cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your cargo quantity is..."
											required
											value={this.state.cargo_quantity}
											onChange={this.onCargoQuantityChange}
										></input>
										<br></br>
									</div>
									<div className="cargo-quantity-text-hints">
										<label>text hints in docs</label>
										<div className="error"></div>
										<input
											type="text"
											name="cargo quantity text hints"
											placeholder="cargo total quantity, e.g. total MT"
											// required
											value={this.state.cargo_quantity_hint}
											onChange={this.onCargoQuantityHintsChange}
										></input>
										<button onClick={this.updateCargoQuantity}>Update</button>
										<br></br>
									</div>
								</div>
								<div className="cargo-weight-div">
									<div className="cargo-weight">
										<label>Cargo Weight</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.cargo_weight_error ? <div>{"Cargo weight cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your cargo weight is..."
											required
											value={this.state.cargo_weight}
											onChange={this.onCargoWeightChange}
										></input>
										<br></br>
									</div>
									<div className="cargo-weight-text-hints">
										<label>text hints in docs</label>
										<div className="error"></div>
										<input
											type="text"
											name="cargo weight text hints"
											placeholder="cargo total weight, e.g. total MT"
											// required
											// value={this.state.commodity_name}
											onChange={this.onCargoWeightHintsChange}
										></input>
										<button onClick={this.updateCargoWeight}>Update</button>
										<br></br>
									</div>
								</div>
								<div className="cargo-number-of-containers-div">
									<div className="cargo-number-of-containers">
										<label>Cargo Number of Containers</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.cargo_number_of_containers_error ? (
												<div>{"Cargo number of containers cannot be empty"}</div>
											) : (
												""
											)}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your cargo number of containers is..."
											required
											value={this.state.cargo_number_of_containers}
											onChange={this.onCargoNumberOfContainersChange}
										></input>
										<br></br>
									</div>
									<div className="cargo-number-of-containers-text-hints">
										<label>text hints in docs</label>
										<div className="error"></div>
										<input
											type="text"
											name="cargo number of containers text hints"
											placeholder="cargo number of containers, e.g. # container"
											// required
											// value={this.state.commodity_name}
											onChange={this.onCargoNumberOfContainersHintsChange}
										></input>
										<button onClick={this.updateCargoNumberOfContainers}>Update</button>
										<br></br>
									</div>
								</div>
								<div className="insured-value-div">
									<div className="insured-value">
										<label>Insured Value</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.insured_value_error ? <div>{"Insured value cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your insured value is..."
											required
											value={this.state.insured_value}
											onChange={this.onInsuredValueChange}
										></input>
										<br></br>
									</div>
								</div>
								<div className="beneficiary-div">
									<div className="beneficiary">
										<label>Beneficiary</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.beneficiary_error ? <div>{"Beneficiary cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your beneficiary is..."
											required
											value={this.state.beneficiary}
											onChange={this.onBeneficiaryChange}
										></input>
										<br></br>
									</div>
									<div className="beneficiary-text-hints">
										<label>text hints in docs</label>
										<div className="error"></div>
										<input
											type="text"
											name="beneficiary text hints"
											placeholder="beneficiary name hint text, e.g. Beneficiary"
											onChange={this.onBeneficiaryHintsChange}
										></input>
										<br></br>
									</div>
								</div>
								<div className="port-of-discharge-div">
									<div className="port-of-discharge">
										<label>Port of Discharge</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.port_of_discharge_error ? <div>{"Port of discharge cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your port of discharge is..."
											required
											value={this.state.port_of_discharge}
											onChange={this.onPortOfDischargeChange}
										></input>
										<br></br>
									</div>
									<div className="port-of-discharge-text-hints">
										<label>text hints in docs</label>
										<div className="error"></div>
										<input
											type="text"
											name="port of discharge text hints"
											placeholder="port of discharge hint text, e.g. POD"
											onChange={this.onPortOfDischargeHintsChange}
										></input>
										<br></br>
									</div>
								</div>
								<div className="port-of-loading-div">
									<div className="port-of-loading">
										<label>Port of Loading</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.port_of_loading_error ? <div>{"Port of loading cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your port of loading is..."
											required
											value={this.state.port_of_loading}
											onChange={this.onPortOfLoadingChange}
										></input>
										<br></br>
									</div>
									<div className="port-of-loading-text-hints">
										<label>text hints in docs</label>
										<div className="error"></div>
										<input
											type="text"
											name="port of loading text hints"
											placeholder="port of loading hint text, e.g. POL"
											onChange={this.onPortOfLoadingHintsChange}
										></input>
										<br></br>
									</div>
								</div>
								<div className="ETD-div">
									<div className="ETD">
										<label>Estimated Time of Departure(Date)</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.ETD_error ? <div>{"Estimated time of departure cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your estimated time of departure is..."
											required
											value={this.state.ETD}
											onChange={this.onETDChange}
										></input>
										<br></br>
									</div>
									<div className="ETD-text-hints">
										<label>text hints in docs</label>
										<div className="error"></div>
										<input
											type="text"
											name="ETD text hints"
											placeholder="ETD hint text, e.g. ETD"
											onChange={this.onETDHintsChange}
										></input>
										<br></br>
									</div>
								</div>
								<div className="ETA-div">
									<div className="ETA">
										<label>Estimated Time of Arrival(Date)</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.ETA_error ? <div>{"Estimated time of arrival cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your estimated time of arrival is..."
											required
											value={this.state.ETA}
											onChange={this.onETAChange}
										></input>
										<br></br>
									</div>
									<div className="ETA-text-hints">
										<label>text hints in docs</label>
										<div className="error"></div>
										<input
											type="text"
											name="ETA text hints"
											placeholder="ETA hint text, e.g. ETA"
											onChange={this.onETAHintsChange}
										></input>
										<br></br>
									</div>
								</div>
								<div className="currency-of-claim-div">
									<div className="currency-of-claim">
										<label>Currency of Claim</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.currency_of_claim_error ? <div>{"Currency of claim cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your currency of claim is..."
											required
											value={this.state.currency_of_claim}
											onChange={this.onCurrencyofClaimChange}
										></input>
										<br></br>
									</div>
								</div>
								<div className="location-of-claim-payment-div">
									<div className="location-of-claim-payment">
										<label>Location of Claim Payment</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.location_of_claim_payment_error ? (
												<div>{"Location of claim payment cannot be empty"}</div>
											) : (
												""
											)}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your location of claim payment is..."
											required
											value={this.state.location_of_claim_payment}
											onChange={this.onLocationofClaimPaymentChange}
										></input>
										<br></br>
									</div>
								</div>
								<div className="payment-method-div">
									<div className="payment-method">
										<label>Payment Method</label>
										<span style={{ color: "red" }}>*</span>
										<div className="error">
											{this.state.method_of_claim_payment_error ? <div>{"Payment method cannot be empty"}</div> : ""}
										</div>
										<input
											type="text"
											name="Title"
											placeholder="Your payment method is..."
											required
											value={this.state.method_of_claim_payment}
											onChange={this.onPaymentMethodChange}
										></input>
										<br></br>
									</div>
								</div>
								<div className="policy-choice-checkbox">
									<label>Policy Choice</label>
									<span style={{ color: "red" }}>*</span>
									<div className="policy-choice-checkbox-label">Choice1: All Risks</div>
									<input
										className="checkbox-input"
										onChange={this.handleChoice1Change}
										type="checkbox"
										name="preview-approved"
										value="Preview Approved"
										checked={this.state.choice1selected}
									/>
									<div className="policy-choice-checkbox-label">Choice2: War Risks</div>
									<input
										className="checkbox-input"
										onChange={this.handleChoice2Change}
										type="checkbox"
										name="preview-approved"
										value="Preview Approved"
										checked={this.state.choice2selected}
									/>
									<div className="policy-choice-checkbox-label">Choice3: Strike Risks</div>
									<input
										className="checkbox-input"
										onChange={this.handleChoice3Change}
										type="checkbox"
										name="preview-approved"
										value="Preview Approved"
										checked={this.state.choice3selected}
									/>
								</div>

								<div className="submit-div">
									<input
										type="submit"
										disabled={
											this.state.cargo_number_of_containers_error ||
											this.state.insured_value_error ||
											this.state.beneficiary_error ||
											this.state.port_of_discharge_error ||
											this.state.port_of_loading_error ||
											this.state.ETD_error ||
											this.state.ETA_error ||
											this.state.currency_of_claim_error ||
											this.state.location_of_claim_payment_error ||
											this.state.method_of_claim_payment_error ||
											!(this.state.choice1selected || this.state.choice2selected || this.state.choice3selected)
										}
										value="Publish"
										onClick={this.handleSubmitByRedux}
									></input>
								</div>
							</form>
						</div>
					)}

					{this.state.purchaseStep == 3 && (
						<div className="preview-div">
							{this.state.previewReady ? (
								<div className="email-preview-button">
									<div className="email-div">
										{/* <label className="email-label">Email</label>
									<span className="email-span">*</span> */}
										<label className="email-label">
											Email
											<span className="error">{this.state.emailError ? "*" : ""}</span>
										</label>
										<input
											className="email-input"
											type="text"
											name="email"
											placeholder="Your email.."
											required
											onChange={this.onEmailChange}
											// value={this.state.email}
										></input>
									</div>
									<button className="preview-button">Submit Email</button>
								</div>
							) : (
								<div></div>
							)}
							<div>
								<h3>this is the policy you created</h3>
								<div>{JSON.stringify(this.props.insurancePolicy)}</div>
							</div>
						</div>
					)}

					{this.state.purchaseStep == 4 && <div>Step 4 Payment</div>}

					{this.state.purchaseStep == 5 && <div>Step 5 Finsish</div>}
				</div>
			</div>
		);
	}

	@autobind
	//Obtain filePaths that are uploaded from the FilesUploader
	private onFilesUpdated(fileInofs: { id: number; name: string; filePath: string }[]) {
		let newFilePaths = [];
		for (let i = 0; i < fileInofs.length; i++) {
			newFilePaths.push(fileInofs[i].filePath);
		}
		this.setState({ filePaths: newFilePaths, uploaded: newFilePaths.length >= 1 ? true : false });
	}

	@autobind
	private onEmailSubmit(event: any) {
		event.preventDefault();
		if (this.state.email && !this.state.emailError) {
			//get to back-end and back-end will return to here with a response
			//and get a token
			const backend_uri = "/api/insurance/verifyInsuranceBuyerByEmail";
			const data = {
				email: this.state.email
			};
			const config = {
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json"
				}
			};
			Axios.post(backend_uri, data, config)
				.then((response: any) => {
					if (response.data.success == true && response.data.result.verified == true) {
						this.setState({
							emailExists: response.data.result.verified,
							purchaseStep: 2,
							insurance_buyer_id: response.data.result.insurance_buyer_id
						});
						//will move to step 2 if email confirmed and token received
						//On the email confirmation, we are moving ot second step,
						//then we should call the back-end and get an empty policy started.
						this.initializeInsurancePolicyApplication();
					} else {
						throw new Error("Error: failed verifying your email");
					}
				})
				.catch((error) => {
					alert("Account does not exist, please try again or subscribe first");
					this.setState({
						emailExists: false,
						email: "", //clear the email and show the error
						emailError: true,
						insurance_buyer_id: "",
						emailErrorMessage: "Account does not exist, please try again or subscribe first"
					});
				});
		}
	}

	initializeInsurancePolicyApplication = () => {
		const input: any = {
			insurance_buyer_id: this.state.insurance_buyer_id,
			email: this.state.email,
			commodity_name: this.state.commodity_name,
			invoice_number: this.state.invoice_number,
			shipment_carrier: this.state.shipment_carrier,
			shipment_vessel: this.state.shipment_vessel,
			shipment_voyage: this.state.shipment_voyage,
			cargo_value: this.state.cargo_value,
			cargo_quantity: this.state.cargo_quantity,
			cargo_weight: this.state.cargo_weight,
			cargo_number_of_containers: this.state.cargo_number_of_containers,
			insured_value: this.state.insured_value,
			beneficiary: this.state.beneficiary,
			port_of_discharge: this.state.port_of_discharge,
			port_of_loading: this.state.port_of_loading,
			ETD: this.state.ETD,
			ETA: this.state.ETA,
			currency_of_claim: this.state.currency_of_claim,
			location_of_claim_payment: this.state.location_of_claim_payment,
			method_of_claim_payment: this.state.method_of_claim_payment,
			policy_choice: this.state.policy_choice
		};
		this.props.createInsurancePolicy(input, this.showSuccessAfterCreate);
	};

	showSuccessAfterCreate = (id: string) => {
		this.setState({ id: id });
	};

	@autobind
	private onEmailChange(event: any) {
		event.preventDefault();
		// this.setState({ emailError: false, emailErrorMessage: "" });
		const emailStr = String(event.target.value).trim().toLowerCase();
		if (!validator.isEmail(emailStr)) {
			this.setState({ emailError: true, emailErrorMessage: "" });
		} else {
			this.setState({ emailError: false, emailErrorMessage: "", email: emailStr });
		}
	}

	@autobind
	private onCommodityNameChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ commodity_name_error: true });
		} else {
			this.setState({ commodity_name_error: false, commodity_name: event.target.value });
		}
	}
	@autobind
	private onCommodityNameHintsChange(event: any) {
		event.preventDefault();
		// if (event.target.value.length === 0) {
		// 	this.setState({ commodity_name_error: true });
		// } else {
		// 	this.setState({ commodity_name_error: false,commodity_name_hint: event.target.value });
		// }
	}

	@autobind
	private onInvoiceNumberChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ invoice_number_error: true });
		} else {
			this.setState({ invoice_number_error: false, invoice_number: event.target.value });
		}
	}
	@autobind
	private onInvoiceNumberHintsChange(event: any) {
		event.preventDefault();
		// if (event.target.value.length === 0) {
		// 	this.setState({ invoice_number_error: true });
		// } else {
		// 	this.setState({ invoice_number_error: false,invoice_number_hint: event.target.value });
		// }
	}

	@autobind
	private onShipmentCarrierChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ shipment_carrier_error: true });
		} else {
			this.setState({ shipment_carrier_error: false, shipment_carrier: event.target.value });
		}
	}

	private onShipmentCarrierHintsChange = () => {};

	@autobind
	private onShipmentVesselChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ shipment_vessel_error: true });
		} else {
			this.setState({
				shipment_vessel_error: false,
				shipment_vessel: event.target.value
			});
		}
	}

	private onShipmentVesselHintsChange = () => {};

	@autobind
	private onShipmentVoyageChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ shipment_voyage_error: true });
		} else {
			this.setState({ shipment_voyage_error: false, shipment_voyage: event.target.value });
		}
	}

	private onShipmentVoyageHintsChange = () => {};

	@autobind
	private onCargoValueChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ cargo_value_error: true });
		} else {
			this.setState({ cargo_value_error: false, cargo_value: event.target.value });
		}
	}

	private onCargoValueHintsChange = (event: any) => {
		event.preventDefault();
		if (event.target.value.length === 0) {
			//do nothing here
			// this.setState({ cargo_value_error: true });
		} else {
			this.setState({
				// cargo_value_error: false
				cargo_value_hint: event.target.value
			});
		}
	};

	private updateCargoValue = (event: any) => {
		event.preventDefault();
		//call the backend and ask to retrieve the cargo weight data using the new hint
		let backend_uri;

		backend_uri = "/api/chatwithdocs/getCargoValueFromDocsStevens";

		// const backend_uri = "/api/chatwithdocs/getInsuranceDataFromDocs";
		const data = {
			// filePaths: this.state.filePaths
			cargo_value_hint: this.state.cargo_value_hint,
			textKey: this.state.textKey,
			username: this.state.username
		};
		const config = {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json"
			}
		};

		// console.log("this is my call args:", data, backend_uri, config);

		Axios.post(backend_uri, data, config)
			.then((response) => {
				console.log("Here is the response", response);
				if (response.data && response.data.success == true) {
					// alert("Your data have been obtained. \n");
					this.setState({
						retrieved: true,
						cargo_value: response.data.result.data.cargo_value
					});
				} else {
					throw new Error("Error: failed retrieving data");
				}
			})
			.catch((error) => {
				this.setState({
					retrieved: false
				});
				alert("Failed retrieving data. Please try again." + error);
			});
	};

	@autobind
	private onCargoQuantityChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ cargo_quantity_error: true });
		} else {
			this.setState({ cargo_quantity_error: false, cargo_quantity: event.target.value });
		}
	}

	private onCargoQuantityHintsChange = (event: any) => {
		event.preventDefault();
		if (event.target.value.length === 0) {
			//do nothing here
			// this.setState({ cargo_weight_error: true });
		} else {
			// this.setState({  });
			this.setState({
				//  cargo_weight_error: false
				cargo_quantity_hint: event.target.value
			});
		}
	};

	private updateCargoQuantity = (event: any) => {
		event.preventDefault();
		//call the backend and ask to retrieve the cargo weight data using the new hint
		let backend_uri;
		backend_uri = "/api/chatwithdocs/getCargoQuantityFromDocsStevens";

		const data = {
			// filePaths: this.state.filePaths
			cargo_quantity_hint: this.state.cargo_quantity_hint,
			textKey: this.state.textKey,
			username: this.state.username
		};
		const config = {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json"
			}
		};

		// console.log("this is my call args:", data, backend_uri, config);

		Axios.post(backend_uri, data, config)
			.then((response) => {
				console.log("Here is the response", response);
				if (response.data && response.data.success == true) {
					// alert("Your data have been obtained. \n");
					this.setState({
						retrieved: true,
						cargo_quantity: response.data.result.data.cargo_quantity
					});
				} else {
					throw new Error("Error: failed retrieving data");
				}
			})
			.catch((error) => {
				this.setState({
					retrieved: false
				});
				alert("Failed retrieving data. Please try again." + error);
			});
	};

	@autobind
	private onCargoWeightChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ cargo_weight_error: true });
		} else {
			this.setState({ cargo_weight_error: false, cargo_weight: event.target.value });
		}
	}

	private onCargoWeightHintsChange = (event: any) => {
		event.preventDefault();
		if (event.target.value.length === 0) {
			//do nothing here
			// this.setState({ cargo_weight_error: true });
		} else {
			this.setState({
				// cargo_weight_error: false
				cargo_weight_hint: event.target.value
			});
		}
	};

	private updateCargoWeight = () => {
		//call the backend and ask to retrieve the cargo weight data using the new hint

		let backend_uri;
		backend_uri = "/api/chatwithdocs/getCargoWeightFromDocsStevens";
		const data = {
			cargo_weight_hint: this.state.cargo_weight_hint,
			textKey: this.state.textKey,
			username: this.state.username
		};
		const config = {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json"
			}
		};

		Axios.post(backend_uri, data, config)
			.then((response) => {
				console.log("Here is the response", response);
				if (response.data.success == true) {
					alert("Your data have been obtained. \n");
					this.setState({
						// retrieved: true,
						cargo_weight: response.data.result.data.cargo_weight
					});
				} else {
					throw new Error("Error: failed retrieving data");
				}
			})
			.catch((error) => {
				// this.setState({
				// 	// retrieved: false
				// });
				alert("Failed retrieving data. Please try again." + error);
			});
	};

	@autobind
	private onCargoNumberOfContainersChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ cargo_number_of_containers_error: true });
		} else {
			this.setState({
				cargo_number_of_containers_error: false, //
				cargo_number_of_containers: event.target.value
			});
		}
	}

	private onCargoNumberOfContainersHintsChange = (event: any) => {
		event.preventDefault();
		if (event.target.value.length === 0) {
			//do nothing here
			// this.setState({ cargo_weight_error: true });
		} else {
			this.setState({
				// cargo_weight_error: false
				cargo_number_of_containers_hint: event.target.value
			});
		}
	};

	private updateCargoNumberOfContainers = (event: any) => {
		//call the backend and ask to retrieve the cargo weight data using the new hint
		event.preventDefault();
		let backend_uri;
		backend_uri = "/api/chatwithdocs/getCargoNumberOfContainersFromDocsStevens";
		const data = {
			// filePaths: this.state.filePaths
			cargo_number_of_containers_hint: this.state.cargo_number_of_containers_hint,
			textKey: this.state.textKey,
			username: this.state.username
		};
		const config = {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json"
			}
		};

		Axios.post(backend_uri, data, config)
			.then((response) => {
				console.log("Here is the response", response);
				if (response.data.success == true) {
					alert("Your data have been obtained. \n");
					this.setState({
						// retrieved: true,
						cargo_number_of_containers: response.data.result.data.cargo_number_of_containers
					});
				} else {
					throw new Error("Error: failed retrieving data");
				}
			})
			.catch((error) => {
				this.setState({
					retrieved: false
				});
				alert("Failed retrieving data. Please try again." + error);
			});
	};

	@autobind
	private onInsuredValueChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ insured_value_error: true });
		} else {
			this.setState({
				insured_value_error: false, //
				insured_value: event.target.value
			});
		}
	}

	@autobind
	private onBeneficiaryChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ beneficiary_error: true });
		} else {
			this.setState({
				beneficiary_error: false, //
				beneficiary: event.target.value
			});
		}
	}

	private onBeneficiaryHintsChange = () => {};

	@autobind
	private onPortOfDischargeChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ port_of_discharge_error: true });
		} else {
			this.setState({
				port_of_discharge_error: false, //
				port_of_discharge: event.target.value
			});
		}
	}

	private onPortOfDischargeHintsChange = () => {};

	@autobind
	private onPortOfLoadingChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ port_of_loading_error: true });
		} else {
			this.setState({
				port_of_loading_error: false, //
				port_of_loading: event.target.value
			});
		}
	}

	private onPortOfLoadingHintsChange = () => {};

	@autobind
	private onETDChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ ETD_error: true });
		} else {
			this.setState({
				ETD_error: false, //
				ETD: event.target.value
			});
		}
	}

	private onETDHintsChange = () => {};

	@autobind
	private onETAChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ ETA_error: true });
		} else {
			this.setState({
				ETA_error: false, //
				ETA: event.target.value
			});
		}
	}

	private onETAHintsChange = () => {};

	@autobind
	private onCurrencyofClaimChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ currency_of_claim_error: true });
		} else {
			this.setState({
				currency_of_claim_error: false, //
				currency_of_claim: event.target.value
			});
		}
	}

	@autobind
	private onLocationofClaimPaymentChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ location_of_claim_payment_error: true });
		} else {
			this.setState({
				location_of_claim_payment_error: false, //
				location_of_claim_payment: event.target.value
			});
		}
	}

	@autobind
	private onPaymentMethodChange(event: any) {
		event.preventDefault();
		if (event.target.value.length === 0) {
			this.setState({ method_of_claim_payment_error: true });
		} else {
			this.setState({
				method_of_claim_payment_error: false, //
				method_of_claim_payment: event.target.value
			});
		}
	}

	@autobind
	private handleChoice1Change(event: any) {
		const oldStatus = this.state.choice1selected;
		this.setState({ choice1selected: !oldStatus });
	}

	@autobind
	private handleChoice2Change(event: any) {
		const oldStatus = this.state.choice2selected;
		this.setState({ choice2selected: !oldStatus });
	}

	@autobind
	private handleChoice3Change(event: any) {
		const oldStatus = this.state.choice3selected;
		this.setState({ choice3selected: !oldStatus });
	}

	@autobind
	private async handleSubmitByRedux(event: any) {
		event.preventDefault();
		if (this.state.id === "") {
			return;
		}
		if (this.state.email === "") {
			return;
		} else {
			//alert("Invalid company name!");
		}

		if (this.state.commodity_name === "") {
			return;
		} else {
			//alert("Invalid company size!");
		}

		if (this.state.shipment_carrier === "") {
			return;
		} else {
			//alert("Invalid company size!");
		}

		if (this.state.shipment_vessel === "") {
			return;
		}

		if (this.state.shipment_voyage === "") {
			return;
		}

		if (this.state.cargo_value === 0) {
			return;
		} else {
			//alert("Invalid company website!");
		}

		if (this.state.cargo_quantity === 0) {
			return;
		} else {
			//alert("Invalid company revenue!");
		}

		if (this.state.cargo_weight === 0) {
			return;
		} else {
			//alert("Invalid company revenue!");
		}

		if (this.state.cargo_number_of_containers === 0) {
			return;
		} else {
			//alert("Invalid company revenue!");
		}

		if (this.state.insured_value === 0) {
			return;
		} else {
			//alert("Invalid company revenue!");
		}

		if (this.state.beneficiary === "") {
			return;
		} else {
			//alert("Invalid company revenue!");
		}

		if (this.state.port_of_discharge === "") {
			return;
		} else {
			//alert("Invalid company revenue!");
		}

		if (this.state.port_of_loading === "") {
			return;
		} else {
			//alert("Invalid company revenue!");
		}

		if (this.state.ETD === "") {
			return;
		} else {
			//alert("Invalid company revenue!");
		}

		if (this.state.ETA === "") {
			return;
		} else {
			//alert("Invalid company revenue!");
		}

		if (this.state.currency_of_claim === "") {
			return;
		} else {
			//alert("Invalid company revenue!");
		}

		if (this.state.location_of_claim_payment === "") {
			return;
		} else {
			//alert("Invalid company revenue!");
		}

		if (this.state.method_of_claim_payment === "") {
			return;
		} else {
			//alert("Invalid company revenue!");
		}

		// if (this.state.policy_choice.length == 0) {
		// 	return;
		// } else {
		// 	//alert("Invalid company revenue!");
		// }

		var policy_choice = [];
		if (this.state.choice1selected) {
			policy_choice.push(1);
		}
		if (this.state.choice2selected) {
			policy_choice.push(2);
		}
		if (this.state.choice3selected) {
			policy_choice.push(3);
		}

		const input: any = {
			id: this.state.id, //id ofthe policy to update
			insurance_buyer_id: this.state.insurance_buyer_id,
			email: this.state.email,
			commodity_name: this.state.commodity_name,
			shipment_carrier: this.state.shipment_carrier,
			shipment_vessel: this.state.shipment_vessel,
			shipment_voyage: this.state.shipment_voyage,
			cargo_value: this.state.cargo_value,
			cargo_quantity: this.state.cargo_quantity,
			cargo_weight: this.state.cargo_weight,
			cargo_number_of_containers: this.state.cargo_number_of_containers,
			insured_value: this.state.insured_value,
			beneficiary: this.state.beneficiary,
			port_of_discharge: this.state.port_of_discharge,
			port_of_loading: this.state.port_of_loading,
			ETD: this.state.ETD,
			ETA: this.state.ETA,
			currency_of_claim: this.state.currency_of_claim,
			location_of_claim_payment: this.state.location_of_claim_payment,
			method_of_claim_payment: this.state.method_of_claim_payment,
			//policy_choice: this.state.policy_choice
			policy_choice: policy_choice
		};

		this.props.updateInsurancePolicy(input, this.showSuccessAfterUpdate);
	}

	showSuccessAfterUpdate = () => {
		alert("good news, your insurance application has been submitted successfully!");
	};

	handleIngestData = (event: any) => {
		event.preventDefault();
		// let backend_uri = "/api/insurance/ingestInvoiceAndPackingList";
		let backend_uri;

		backend_uri = "/api/insurance/ingestInvoiceAndPackingListStevens";

		// backend_uri = "/api/insurance/ingestInvoiceAndPackingList";

		const data = {
			filePaths: this.state.filePaths,
			id: this.state.id, //policy id
			username: this.state.username
		};
		const config = {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json"
			}
		};
		Axios.post(backend_uri, data, config)
			.then((response) => {
				if (response.data.success == true) {
					alert("Your files have been ingested. \n You can continue to query the data now.");
					this.setState({
						ingested: true,
						textKey: response.data.result.textKey
					});
				} else {
					throw new Error("Error: failed ingesting data");
				}
			})
			.catch((error) => {
				this.setState({
					ingested: false,
					textKey: ""
				});
				alert("Failure ingesting your data. Please try again." + error);
			});
	};

	handleGetInsuranceDataFromDocs = (event: any) => {
		event.preventDefault();
		let backend_uri;
		backend_uri = "/api/chatwithdocs/getInsuranceDataFromDocsStevens";

		const data = {
			// filePaths: this.state.filePaths
			textKey: this.state.textKey,
			username: this.state.username
		};
		const config = {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json"
			}
		};
		// Axios.post(backend_uri, data, config)
		Axios.post(backend_uri, data, config)
			.then((response) => {
				if (response.data.success == true) {
					alert("Your data have been obtained. \n");
					this.setState({
						retrieved: true,
						commodity_name: response.data.result.data.commodity_name,
						invoice_number: response.data.result.data.invoice_number,
						shipment_carrier: response.data.result.data.shipment_carrier,
						shipment_vessel: response.data.result.data.shipment_vessel,
						shipment_voyage: response.data.result.data.shipment_voyage,
						cargo_value: response.data.result.data.cargo_value,
						cargo_quantity: response.data.result.data.cargo_quantity,
						cargo_weight: response.data.result.data.cargo_weight,
						cargo_number_of_containers: response.data.result.data.cargo_number_of_containers,
						insured_value: response.data.result.data.insured_value,
						beneficiary: response.data.result.data.beneficiary,
						port_of_discharge: response.data.result.data.port_of_discharge,
						port_of_loading: response.data.result.data.port_of_loading,
						ETD: response.data.result.data.ETD,
						ETA: response.data.result.data.ETA,
						currency_of_claim: response.data.result.data.currency_of_claim,
						location_of_claim_payment: response.data.result.data.location_of_claim_payment,
						method_of_claim_payment: response.data.result.data.method_of_claim_payment,
						policy_choice: response.data.result.data.policy_choice
					});
				} else {
					throw new Error("Error: failed retrieving data");
				}
			})
			.catch((error) => {
				this.setState({
					retrieved: false
				});
				alert("Failed retrieving data. Please try again." + error);
			});
	};
}

const mapStateToProps = ({ insurancepolicy }: ApplicationState) => {
	return {
		insurancePolicy: insurancepolicy.insurancePolicy
	};
};

const mapDispatchToProps = {
	createInsurancePolicy: createInsurancePolicy,
	updateInsurancePolicy: updateInsurancePolicy
	// checkInsuranceBuyerEmail: checkInsuranceBuyerEmail
};

function InsurancePurchase(props: any) {
	let navigate = useNavigate();
	const params = useParams();
	return <InsurancePurchaseDtl {...{ ...props, match: { params } }} navigate={navigate} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(InsurancePurchase);
