//reducer is used to change state based on type of action and previous state
import {
	// combineReducers,
	Reducer
	//  AnyAction
} from "redux";
import { InsurancePolicyState, InsurancePolicyActionTypes } from "./InsurancePolicyTypes";

//create Type-safe initialState
export const initialState: InsurancePolicyState = { insurancePolicies: [], insurancePolicy: {} };

// id: "", //by default english
// policy_number: "",
// commodity_name: "",
// shipment_carrier: "",
// shipment_vessel: "",
// shipment_voyage: "",
// cargo_value: 0,
// cargo_quantity: 0,
// cargo_weight: 0,
// cargo_number_of_containers: 0,
// insured_value: 0,
// beneficiary: "",
// port_of_discharge: "",
// port_of_loading: "",
// ETD: "",
// ETA: "",
// currency_of_claim: "",
// location_of_claim_payment: "",
// method_of_claim_payment: "",
// policy_choice: []

const insurancepolicyReducer: Reducer<InsurancePolicyState> = (state = initialState, action: any) => {
	switch (action.type) {
		// case fetch success
		case InsurancePolicyActionTypes.FETCH_INSURANCEPOLICIES_SUCCESS:
			return { ...state, insurancePolicy: action.payload.insurancePolicy }; //must pass back myOptions here from Thunk or Saga

		//case fetch failure:
		case InsurancePolicyActionTypes.FETCH_INSURANCEPOLICIES_ERROR:
			return state;

		//case create success:
		case InsurancePolicyActionTypes.CREATE_INSURANCEPOLICY_SUCCESS:
			//here we should update the state using data in teh action payload
			return {
				...state,
				insurancePolicy: action.payload.insurancePolicy
				//id: action.payload.insurancepolicy._id
			};
		//the data from back-end after new insurancepolicy is added to Database

		case InsurancePolicyActionTypes.CREATE_INSURANCEPOLICY_ERROR:
			//case create failure:
			return state; // no change to the state

		case InsurancePolicyActionTypes.UPDATE_INSURANCEPOLICY_SUCCESS:
			//here we should update the state using the data in the action payload
			//the data from back-end after new insurancepolicy is added to Database
			if (state.insurancePolicy._id == action.playload.insurancePolicy._id) {
				//only update if the id is the same
				return {
					...state,
					//id: action.payload.insurancepolicy._id
					insurancePolicy: action.payload.insurancePolicy
				};
			} else {
				return state;
			}

		case InsurancePolicyActionTypes.UPDATE_INSURANCEPOLICY_ERROR:
			//case create failure:
			return state; // no change to the state

		default: //by default, no change to state
			return state;
	}
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export default insurancepolicyReducer;
