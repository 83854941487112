import * as React from "react";
// const logo = "/static/images/haymingwaylogo.png";
import validator from "validator";
import autobind from "autobind-decorator";
import "./ContactUsPage.scss";
import Axios from "axios"; //request

// @use "./ContactUsPage.scss";

export default class ContactUsPage extends React.Component<
	{},
	{
		name: string;
		email: string;
		message: string;
		emailError: boolean;
		nameError: boolean;
		messageError: boolean;
	}
> {
	constructor(props: any) {
		super(props);
		this.state = {
			name: "",
			email: "",
			message: "",
			emailError: false,
			nameError: false,
			messageError: false
		};
	}

	componentDidMount() {}

	render() {
		return (
			<div>
				<div className="container">
					<div className="header-div">
						<h3 className="major">Contact Us</h3>
					</div>
					<form>
						<div className="name-div">
							<label>Name</label>
							<span style={{ color: "red" }}>*</span>
							<div className="error">{this.state.nameError ? <div>{"Name cannot be empty"}</div> : ""}</div>
							<input type="text" name="name" placeholder="Your name.." required onChange={this.onNameChange}></input>
							<br></br>
						</div>
						<div className="email-div">
							<label>Email</label>
							<span style={{ color: "red" }}>*</span>
							<div className="error">{this.state.emailError ? <div>{"Please give valid email address"}</div> : ""}</div>
							<input type="text" name="email" placeholder="Your email.." required onChange={this.onEmailChange}></input>
							<br></br>
						</div>
						<div className="message-div">
							<label>Your message</label>
							<span style={{ color: "red" }}>*</span>
							<div className="error">{this.state.messageError ? <div>{"message cannot be empty"}</div> : ""}</div>
							<textarea
								id="subject"
								placeholder="Tell us your inquiry..."
								required
								onChange={this.onMessageChange}
							></textarea>
							<br></br>
							<input type="submit" value="Submit" onClick={this.handleSubmit}></input>
						</div>
					</form>
				</div>
			</div>
		);
	}

	@autobind
	private onNameChange(event: any) {
		event.preventDefault();
		if (!event.target.value.trim().length) {
			this.setState({ nameError: true });
		} else {
			this.setState({ nameError: false });
			this.setState({ name: event.target.value });
		}
	}

	@autobind
	private onEmailChange(event: any) {
		event.preventDefault();
		if (!validator.isEmail(String(event.target.value).toLowerCase())) {
			this.setState({ emailError: true });
		} else {
			this.setState({ emailError: false });
			this.setState({ email: event.target.value });
		}
	}

	@autobind
	private onMessageChange(event: any) {
		event.preventDefault();
		if (!event.target.value.trim().length) {
			this.setState({ messageError: true });
		} else {
			this.setState({ messageError: false });
			this.setState({ message: event.target.value });
		}
	}

	@autobind
	private handleSubmit(event: any) {
		event.preventDefault();
		if (this.state.email === "") {
			return;
		} else {
			if (!validator.isEmail(String(this.state.email).toLowerCase())) {
				alert("Invalid email address!");
			}
		}

		if (this.state.message === "") {
			return;
		}

		if (this.state.name === "") {
			return;
		}

		const API_ENDPOINT = "/api/contactus";
		const data = {
			name: this.state.name,
			message: this.state.message,
			email: this.state.email
		};

		const config = {
			headers: {
				//'Access-Control-Allow-Origin': '*',
				// 'Content-Type': 'application/json',
			}
		};

		Axios.post(API_ENDPOINT, data, config)
			.then(function (response) {
				alert(
					"Your message has been received. We will get back to you in 24 hours. You will be redirected to Homepage."
				);
				setTimeout(() => {
					// window.location.href = "/index.html";
					window.location.href = "/";
				}, 3000);
			})
			.catch(function (error) {
				alert("Failure sending your message. Please try again.");
			});
	}
}
