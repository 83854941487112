//actions here define the type and the action for each Type identified
import { NewsLetterActionTypes } from "./NewsLetterTypes";
//actions on CRUD of newsletters
//C //Create
export const createNewsLetterSuccess = () => {
	return { type: NewsLetterActionTypes.CREATE_NEWSLETTER_SUCCESS };
};
export const createNewsLetterError = (error: any) => {
	return { type: NewsLetterActionTypes.CREATE_NEWSLETTER_ERROR, error };
};

//R //Read
export const fetchNewsLettersSucess = (myOptions: string[]) => {
	return {
		type: NewsLetterActionTypes.FETCH_NEWSLETTERS_SUCCESS,
		myOptions: myOptions
	};
};
export const fetchNewsLetttersError = (error: any) => {
	return { type: NewsLetterActionTypes.FETCH_NEWSLETTERS_ERROR, error };
};

//U //update

//D //delete
