import * as React from "react";
import validator from "validator";
import autobind from "autobind-decorator";
import "./CareerPage.scss";
import Axios from "axios"; //request

export default class CareerPage extends React.Component<
	{},
	{
		name: string;
		email: string;
		message: string;
		emailError: boolean;
		nameError: boolean;
		messageError: boolean;
	}
> {
	constructor(props: any) {
		super(props);
		this.state = {
			name: "",
			email: "",
			message: "",
			emailError: false,
			nameError: false,
			messageError: false
		};
	}

	componentDidMount() {}

	render() {
		return (
			<div>
				<div className="container">
					<div className="header-div">
						<h3 className="major">Career</h3>
					</div>
					<div className="job-div">
						<label>Job Openings:</label>
						<br></br>
						<div>
							<ul>
								<li>
									<a href="/career/open/jd-20230701-webdeveloper-full-stack.pdf">Fulltime Web Developer (Fullstack)</a>
									<br></br>
								</li>
								<li>
									<a href="/career/open/jd-20230901-ai-promptengineer-intern.pdf">
										AI Engineer - Intern (Prompt Engineer)
									</a>
									<br></br>
								</li>
								<li>
									<a href="/career/open/jd-20240201-webdeveloper-backend-intern.pdf">
										Web Developer -Intern (Back-end)
									</a>
									<br></br>
								</li>
								<li>
									<a href="/career/open/jd-20240202-webdeveloper-frontend-intern.pdf">
										Web Developer -Intern (Front-end)
									</a>
									<br></br>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
