//actions here define the type and the action for each Type identified
import { InsuranceBuyerActionTypes } from "./InsuranceBuyerTypes";
//actions on CRUD of newsletters
//C //Create
export const createInsuranceBuyerSuccess = (insurancebuyer: any) => {
	//plain action (no functions, only data)
	return { type: InsuranceBuyerActionTypes.CREATE_INSURANCEBUYER_SUCCESS, payload: { insurancebuyer: insurancebuyer } };
};

export const createInsuranceBuyerError = (error: any) => {
	return { type: InsuranceBuyerActionTypes.CREATE_INSURANCEBUYER_ERROR, error };
};

export const createInsuranceBuyerOnBoardSuccess = (insurancebuyer: any) => {
	//plain action (no functions, only data)
	return {
		type: InsuranceBuyerActionTypes.CREATE_INSURANCEBUYER_ONBOARD_SUCCESS,
		payload: { insurancebuyer: insurancebuyer }
	};
};

export const createInsuranceBuyerOnBoardError = (error: any) => {
	return { type: InsuranceBuyerActionTypes.CREATE_INSURANCEBUYER_ONBOARD_ERROR, error };
};

//R //Read
export const fetchInsuranceBuyersSucess = (myOptions: string[]) => {
	return {
		type: InsuranceBuyerActionTypes.FETCH_INSURANCEBUYERS_SUCCESS
		// myOptions: myOptions
	};
};
export const fetchInsuranceBuyersError = (error: any) => {
	return { type: InsuranceBuyerActionTypes.FETCH_INSURANCEBUYERS_ERROR, error };
};

//U //update

//D //delete
