// import logo from './logo.svg';
import "./HomePage.scss";

function HomePage() {
	return (
		<div>
			{showBanner()}
			{showFeature()}
			{showMain()}
		</div>
	);
}

const showBanner = () => {
	return (
		// <div id="header">
		<div className="container">
			<div id="banner">
				<div className="container">
					<section>
						<header className="major">
							<h3>Cheaper Pay for Better Hay, Seeing It on Your Way.</h3>
							<span className="byline">
								&hellip; For dairy farms whose lifelines are dependent on secured hay supply, Haymingway does its best
								on sourcing and quality control for them. We do it by contracting with hay growers in USA, inspecting
								all fields and hay stacks, and providing tracking for trucking, hay pressing and container shipments.{" "}
							</span>
						</header>
					</section>
				</div>
			</div>
		</div>
		// </div>
	);
};

const showFeature = () => {
	return (
		<div className="wrapper style2">
			<section className="container">
				<header className="major">
					<h2>Farmers Feed the World</h2>
					<span className="byline">We Help Feed Your Animals</span>
				</header>
				<div className="row no-collapse-1">
					<section className="4u">
						<a href="/#" className="image feature">
							<img src="images/pic02.jpg" alt="" />
						</a>
						<p>
							Our feilds are carefully selected and planted by professionals in the United States. Our annual yield is
							up to 60,000 tons a year.{" "}
						</p>
					</section>
					<section className="4u">
						<a href="/#" className="image feature">
							<img src="images/pic03.jpg" alt="" />
						</a>
						<p>
							We have signed cooperative contracts with hay growers in Washington, Idaho, Oregon, Nevada and California
							to ensure sufficient export supply to China.
						</p>
					</section>
					<section className="4u">
						<a href="/#" className="image feature">
							<img src="images/pic04.jpg" alt="" />
						</a>
						<p>
							All processing procedures comply with the production standards of the US Department of Agriculture and the
							regulations of General Administration of Quality Supervision, Inspection and Quarantine of China (AQSIQ).
						</p>
					</section>
				</div>
			</section>
		</div>
	);
};

const showMain = () => {
	return (
		<div id="main" className="wrapper style1">
			<section className="container">
				<header className="major">
					<h2>Why Haymingway? </h2>
					<span className="byline">We make it just FOUR you!</span>
				</header>
				<div className="row">
					<div className="6u">
						<section>
							<ul className="style">
								<li>
									<span className="fa fa-wrench"></span>
									<h3>One</h3>
									<span>
										We implement comprehensive scientific quality inspection to make sure your hay is at greatest
										quality.
									</span>
								</li>
								<li>
									<span className="fa fa-cloud"></span>
									<h3>Two</h3>
									<span>
										We are in Washington State, known world-wide for hay quality and perfectly suited growing and
										harvesting conditions.{" "}
									</span>
								</li>
							</ul>
						</section>
					</div>
					<div className="6u">
						<section>
							<ul className="style">
								<li>
									<span className="fa fa-cogs"></span>
									<h3>Three</h3>
									<span>
										We offer trackable trade. Every farmer and every haystack plot is marked to ensure that quality
										problems can be traced.
									</span>
								</li>
								<li>
									<span className="fa fa-leaf"></span>
									<h3>Four</h3>
									<span>
										We let you know where your orders and shipments are at. We use leading shipping lines and provide
										real-time tracking for you to check on mobile phones or computers{" "}
									</span>
								</li>
							</ul>
						</section>
					</div>
				</div>
			</section>
		</div>
	);
};

//--
export default HomePage;
