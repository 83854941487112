import {
	all,
	call,
	fork,
	put,
	//  select,
	takeEvery,
	takeLatest
} from "redux-saga/effects";
import { InsuranceBuyerActionTypes } from "./InsuranceBuyerTypes";
import axios from "axios";
// import { getRecentSearches } from "./SearchesActionsCreatorSaga";
import {
	fetchInsuranceBuyersSucess,
	fetchInsuranceBuyersError,
	createInsuranceBuyerSuccess,
	createInsuranceBuyerError,
	createInsuranceBuyerOnBoardSuccess,
	createInsuranceBuyerOnBoardError
} from "./InsuranceBuyerActionsSaga";

//GENERATOR functions are coded here to deal with async processes such as fetch data, and then it will call the
//fucntions in PostsActionsCreateSaga.tsx to do the actual dispatch to update the store.

//--FETCH_INSURANCEBUYERS
function fecthInsuranceBuyersApi(value: string) {
	//const API_ENDPOINT =
	//  process.env.REACT_APP_API_ENDPOINT || "https://api.opendota.com";
	//function that returns an axios call
	const API_ENDPOINT = "/api/insurance/getInsuranceBuyers";

	return axios.get(API_ENDPOINT, {
		params: {
			//value should be the email or name to be searched in database
			value
		}
	});
}
/** saga worker that is responsible for the side effects */
function* fecthInsuranceBuyersSaga(action: any) {
	//GENERATOR function* that calls the fetchPostApi with the argument provided
	try {
		//data is obtained after axios call is resolved
		//after fetch, if success, data should contain the insurancebuyers returned from back-end

		let { data } = yield call(fecthInsuranceBuyersApi, action.payload.input);
		//you may want to store response to localStorage, or not.
		//here is where the dispatch happens,and the data obtained will be used to update the redux-store
		if (data.success === true && data.result.insurancebuyers) {
			let myOptions: any = [];
			const insurancebuyers = data.result.insurancebuyers;
			if (insurancebuyers.length > 0) {
				insurancebuyers.map((insurancebuyer: any) => myOptions.push(insurancebuyer));
			} else {
				myOptions = [];
			}
			yield put(fetchInsuranceBuyersSucess(myOptions));
			//invoke the callBack function in action if needed
		} else {
			const error = new Error("error fetching recent searches");
			throw error;
		}
	} catch (error) {
		yield put(fetchInsuranceBuyersError(error));
	}
}
// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `fecthPostsSaga()` saga above.
function* watchFetchInsuranceBuyer() {
	yield takeLatest(InsuranceBuyerActionTypes.FETCH_INSURANCEBUYERS, fecthInsuranceBuyersSaga);
}

//---Create Search
function submitInsuranceBuyerEnrollmentApi(
	//here should include name, email and preferredLanguage  to submit to back-end
	input: {
		email: string;
		name: string;
	}
	//csrfToken: string
) {
	//const API_ENDPOINT =
	//  process.env.REACT_APP_API_ENDPOINT || "https://api.opendota.com";
	//function that returns an axios call
	const API_ENDPOINT = "/api/insurance/createInsuranceBuyerEnrollment";
	return axios.post(API_ENDPOINT, input, {
		headers: {
			//"X-CSRF-Token": csrfToken
		}
	});
}

/** saga worker that is responsible for the side effects */
function* submitInsuranceBuyerEnrollmentSaga(action: any): any {
	//GENERATOR function* that calls the fetchPostApi with the argument provided
	try {
		//let csrfTokenNew = yield call(fetchCSRFToken);
		//if (csrfTokenNew !== null && csrfTokenNew !== "" && csrfTokenNew !== undefined) {
		//			yield put(fetchCSRFSuccess(csrfTokenNew));
		//		} else {
		//			yield put(fetchCSRFError(new Error("Error fetching CSRF token")));
		//		}
		//		const csrfToken: string = yield select(getCSRFFromStore);

		//data is obtained after axios callis resolved
		let { data } = yield call(
			submitInsuranceBuyerEnrollmentApi, //This is the API that goes to the back-end and
			//
			action.payload.input
			//csrfToken
		);
		//You may want to store data to localStorage, or not.
		//Here is where the dispatch happens,and the posts data obtained
		//ill be used to update the redux-store
		if (data.success === true && data.result) {
			yield put(createInsuranceBuyerSuccess(data.result.insurancebuyer)); //call action in reducer to update state
			yield call(action.meta.callbackAction); //and then call the meta function if needed
		} else {
			throw new Error("Error creating insurancebuyer");
		}
	} catch (error) {
		yield put(createInsuranceBuyerError(error));
		//call the action to handle the error in state
		//some times no thing is done at all
	}
}

//---Create Search
function submitInsuranceBuyerOnBoardApi(
	//here should include name, email and preferredLanguage  to submit to back-end
	input: {
		token: string;
		companyName: string;
		companyAddress: {
			city: string;
			street: string;
			houseNumber: string;
			zipCode: string;
		};
		companyPhoneNumber: string;
		companySize: string;
		companyWebsite: string;
		companyRegistrationYear: string;
		companyRevenue: string;
	}
	//csrfToken: string
) {
	//const API_ENDPOINT =
	//  process.env.REACT_APP_API_ENDPOINT || "https://api.opendota.com";
	//function that returns an axios call
	const API_ENDPOINT = "/api/insurance/createInsuranceBuyerOnBoard";
	return axios.post(API_ENDPOINT, input, {
		headers: {
			//"X-CSRF-Token": csrfToken
		}
	});
}

function* submitInsuranceBuyerOnBoardSaga(action: any): any {
	//GENERATOR function* that calls the fetchPostApi with the argument provided
	try {
		//let csrfTokenNew = yield call(fetchCSRFToken);
		//if (csrfTokenNew !== null && csrfTokenNew !== "" && csrfTokenNew !== undefined) {
		//			yield put(fetchCSRFSuccess(csrfTokenNew));
		//		} else {
		//			yield put(fetchCSRFError(new Error("Error fetching CSRF token")));
		//		}
		//		const csrfToken: string = yield select(getCSRFFromStore);

		//data is obtained after axios callis resolved
		let { data } = yield call(
			submitInsuranceBuyerOnBoardApi, //This is the API that goes to the back-end and
			//
			action.payload.input
			//csrfToken
		);
		//You may want to store data to localStorage, or not.
		//Here is where the dispatch happens,and the posts data obtained
		//ill be used to update the redux-store
		if (data.success === true && data.result) {
			yield put(createInsuranceBuyerOnBoardSuccess(data.result.insurancebuyer)); //call action in reducer to update state
			yield call(action.meta.callbackAction); //and then call the meta function if needed
		} else {
			throw new Error("Error submitting insurancebuyer onboard info");
		}
	} catch (error) {
		yield put(createInsuranceBuyerOnBoardError(error));
		//call the action to handle the error in state
		//some times no thing is done at all
	}
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `submitPostInfoSaga()` saga above.
function* watchSubmitInsuranceBuyerEnrollment() {
	yield takeEvery(InsuranceBuyerActionTypes.CREATE_INSURANCEBUYER, submitInsuranceBuyerEnrollmentSaga);
}

function* watchSubmitInsuranceBuyerOnBoard() {
	yield takeEvery(InsuranceBuyerActionTypes.UPDATE_INSURANCEBUYER, submitInsuranceBuyerOnBoardSaga);
}

//put all above sagas for posts together into postsSaga and export it
// We can also use `fork()` here to split our saga into multiple watchers.
function* insurancebuyersSaga() {
	yield all([
		fork(watchFetchInsuranceBuyer),
		fork(watchSubmitInsuranceBuyerEnrollment),
		fork(watchSubmitInsuranceBuyerOnBoard)
		//,Add other watchers here to the list for handling posts related actions
	]);
}
export default insurancebuyersSaga;
