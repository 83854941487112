//actions here define the type and the action for each Type identified

// This type is basically shorthand for `{ [key: string]: any }`. Feel free to replace `any` with
// the expected return type of your API response.
//export type ApiResponse = Record<string, any>;

export const enum InsuranceBuyerActionTypes {
	//crud (create, read, update, delete)

	//create
	CREATE_INSURANCEBUYER = "@@insurancebuyers/CREATE_INSURANCEBUYER",
	CREATE_INSURANCEBUYER_SUCCESS = "@@insurancebuyers/CREATE_INSURANCEBUYER_SUCCESS",
	CREATE_INSURANCEBUYER_ERROR = "@@insurancebuyers/CREATE_INSURANCEBUYER_ERROR",

	CREATE_INSURANCEBUYER_ONBOARD = "@@insurancebuyers/CREATE_INSURANCEBUYER_ONBOARD",
	CREATE_INSURANCEBUYER_ONBOARD_SUCCESS = "@@insurancebuyers/CREATE_INSURANCEBUYER_ONBOARD_SUCCESS",
	CREATE_INSURANCEBUYER_ONBOARD_ERROR = "@@insurancebuyers/CREATE_INSURANCEBUYER_ONBOARD_ERROR",

	//read
	FETCH_INSURANCEBUYERS = "@@insurancebuyers/FETCH_INSURANCEBUYERS",
	FETCH_INSURANCEBUYERS_SUCCESS = "@@insurancebuyers/FETCH_INSURANCEBUYERS_SUCCESS",
	FETCH_INSURANCEBUYERS_ERROR = "@@insurancebuyers/FETCH_INSURANCEBUYERS_ERROR",

	//update - todo
	UPDATE_INSURANCEBUYER = "@@insurancebuyers/UPDATE_INSURANCEBUYER",
	UPDATE_INSURANCEBUYER_SUCCESS = "@@insurancebuyers/UPDATE_INSURANCEBUYER_SUCCESS",
	UPDATE_INSURANCEBUYER_ERROR = "@@insurancebuyers/UPDATE_INSURANCEBUYER_ERROR",

	//get a code sent to email
	REQUEST_EMAILCODE = "@@insurancebuyers/REQUEST_EMAILCODE",
	REQUEST_EMAILCODE_SUCCESS = "@@insurancebuyers/REQUEST_EMAILCODE_SUCCESS",
	REQUEST_EMAILCODE_ERROR = "@@insurancebuyers/REQUEST_EMAILCODE_ERROR",

	//submit the code received in email
	SUBMIT_EMAILCODE = "@@insurancebuyers/SUBMIT_EMAILCODE",
	SUBMIT_EMAILCODE_SUCCESS = "@@insurancebuyers/SUBMIT_EMAILCODE_SUCCESS",
	SUBMIT_EMAILCODE_ERROR = "@@insurancebuyers/SUBMIT_EMAILCODE_ERROR",

	//submit email and password
	SUBMIT_EMAILPASSWORDINFO = "@@insurancebuyers/SUBMIT_EMAILPASSWORDINFO",
	SUBMIT_EMAILPASSWORDINFO_SUCCESS = "@@insurancebuyers/SUBMIT_EMAILPASSWORDINFO_SUCCESS",
	SUBMIT_EMAILPASSWORDINFO_ERROR = "@@insurancebuyers/SUBMIT_EMAILPASSWORDINFO_ERROR",

	//check if phone registered
	CHECK_PHONEAVAILABLITY = "@@insurancebuyers/CHECK_PHONEAVAILABLITY",
	CHECK_PHONEAVAILABLITY_SUCCESS = "@@insurancebuyers/CHECK_PHONEAVAILABLITY_SUCCESS",
	CHECK_PHONEAVAILABLITY_ERROR = "@@insurancebuyers/CHECK_PHONEAVAILABLITY_ERROR",

	//check if email registered

	CHECK_EMAILAVAILABLITY = "@@insurancebuyers/CHECK_EMAILAVAILABLITY",
	CHECK_EMAILAVAILABLITY_SUCCESS = "@@insurancebuyers/CHECK_EMAILAVAILABLITY_SUCCESS",
	CHECK_EMAILAVAILABLITY_ERROR = "@@insurancebuyers/CHECK_EMAILAVAILABLITY_ERROR",

	//send code by SMS
	SEND_CODEBYSMS = "@@insurancebuyers/SEND_CODEBYSMS",
	SEND_CODEBYSMS_SUCCESS = "@@insurancebuyers/SEND_CODEBYSMS_SUCCESS",
	SEND_CODEBYSMS_ERROR = "@@insurancebuyers/SEND_CODEBYSMS_ERROR",

	//verify code
	VERIFY_CODE = "@@insurancebuyers/VERIFY_CODE",
	VERIFY_CODE_SUCCESS = "@@insurancebuyers/VERIFY_CODE",
	VERIFY_CODE_ERROR = "@@insurancebuyers/VERIFY_CODE_ERROR"

	//delete - todo
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability

// These are going to be in the redux state that the PostsReducer will modify
// upon dispatch with the associated data

export interface InsuranceBuyerState {
	//here we should use real type rather than any***
	//readonly loading: boolean;
	//readonly data: any[];
	//readonly errors?: string;
	// readonly insurancepolicies: any[];
	readonly id: string;
	readonly email: string;
	readonly name: string;
	readonly insurancepolicies: any[];
	readonly companyName: string;
	readonly companyPhoneNumber: string;
	readonly companySize: string;
	readonly companyWebsite: string;
	readonly companyRegistrationYear: string;
	readonly companyRevenue: string;
}
