import {
	all,
	call,
	fork,
	put,
	// select,
	takeEvery,
	takeLatest
} from "redux-saga/effects";
import { NewsLetterActionTypes } from "./NewsLetterTypes";
import axios from "axios";
// import { getRecentSearches } from "./SearchesActionsCreatorSaga";
import {
	fetchNewsLettersSucess,
	fetchNewsLetttersError,
	createNewsLetterSuccess,
	createNewsLetterError
} from "./NewsLetterActionsSaga";

//GENERATOR functions are coded here to deal with async processes such as fetch data, and then it will call the
//fucntions in PostsActionsCreateSaga.tsx to do the actual dispatch to update the store.

//--FETCH_POSTS
function fecthNewsLettersApi(value: string) {
	//const API_ENDPOINT =
	//  process.env.REACT_APP_API_ENDPOINT || "https://api.opendota.com";
	//function that returns an axios call
	const API_ENDPOINT = "/api/newsletter/getNewsLetters";
	//const API_ENDPOINT = "/api/search/getRecentSearch";
	return axios.get(API_ENDPOINT, {
		params: {
			value
		}
	});
	//  ---or you can use the followign way to call the axios
	// return axios.request({
	//   method: "get",
	//   url: API_ENDPOINT,
	//   params: {
	//      value: input
	//   }
	//   //data: {value:input}
	// });
}
/** saga worker that is responsible for the side effects */
function* fecthSearchesSaga(action: any) {
	//GENERATOR function* that calls the fetchPostApi with the argument provided
	try {
		//data is obtained after axios call is resolved
		let { data } = yield call(fecthNewsLettersApi, action.payload.input);
		//you may want to store response to localStorage, or not.
		//here is where the dispatch happens,and the data obtained will be used to update the redux-store
		if (data.success === true && data.result.newsletters) {
			let myOptions: any = [];
			const newsletters = data.result.newsletters;
			if (newsletters.length > 0) {
				newsletters.map((recentSearch: any) => myOptions.push(newsletters));
			} else {
				myOptions = [];
			}
			yield put(fetchNewsLettersSucess(myOptions));
			//invoke the callBack function in action if needed
		} else {
			const error = new Error("error fetching recent searches");
			throw error;
			//yield put(fetchRecentSearchesError(error));
		}
	} catch (error) {
		yield put(fetchNewsLetttersError(error));
	}
}
// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `fecthPostsSaga()` saga above.
function* watchFetchNewsLetters() {
	yield takeLatest(NewsLetterActionTypes.FETCH_NEWSLETTERS, fecthSearchesSaga);
}

//---Create Search
function submitRecentSearchApi(
	input: string
	//csrfToken: string
) {
	//const API_ENDPOINT =
	//  process.env.REACT_APP_API_ENDPOINT || "https://api.opendota.com";
	//function that returns an axios call
	const API_ENDPOINT = "/api/newsletter/createNewsLetter";
	return axios.post(
		API_ENDPOINT,
		{ newletterText: input },
		{
			headers: {
				//"X-CSRF-Token": csrfToken
			}
		}
	);
}

/** saga worker that is responsible for the side effects */
function* submitNewsLetterSearchSaga(action: any): any {
	//GENERATOR function* that calls the fetchPostApi with the argument provided
	try {
		//let csrfTokenNew = yield call(fetchCSRFToken);
		//if (csrfTokenNew !== null && csrfTokenNew !== "" && csrfTokenNew !== undefined) {
		//			yield put(fetchCSRFSuccess(csrfTokenNew));
		//		} else {
		//			yield put(fetchCSRFError(new Error("Error fetching CSRF token")));
		//		}
		//		const csrfToken: string = yield select(getCSRFFromStore);
		//data is obtained after axios callis resolved
		let { data } = yield call(
			submitRecentSearchApi,
			action.payload.input
			//csrfToken
		);
		//You may want to store data to localStorage, or not.
		//Here is where the dispatch happens,and the posts data obtained
		//ill be used to update the redux-store
		if (data.success === true) {
			yield put(createNewsLetterSuccess());
			yield call(action.meta.callbackAction);
		} else {
			throw new Error("Error creating post");
		}
	} catch (error) {
		yield put(createNewsLetterError(error));
	}
}
// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `submitPostInfoSaga()` saga above.
function* watchSubmitNewsLetters() {
	yield takeEvery(NewsLetterActionTypes.CREATE_NEWSLETTER, submitNewsLetterSearchSaga);
}

//put all above sagas for posts together into postsSaga and export it
// We can also use `fork()` here to split our saga into multiple watchers.
function* newslettersSaga() {
	yield all([
		fork(watchFetchNewsLetters),
		fork(watchSubmitNewsLetters)
		//,Add other watchers here to the list for handling posts related actions
	]);
}
export default newslettersSaga;
