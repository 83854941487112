import * as React from "react";
import "./PolicyApplication.scss";
import { ApplicationState, ConnectedReduxProps } from "../../store";
import { createInsurancePolicy } from "../../store/InsurancePolicy/InsurancePolicyActionsCreatorSaga";

import { useParams } from "react-router-dom";
//connect allows a component to use redux store as props of the component
import { connect } from "react-redux";
//react router allows one to navigate from within the component to other components
//import { RouteComponentProps } from "react-router-dom";
import { useNavigate } from "react-router-dom";

interface PropsForSelf {
	match: {
		params: any;
	};
}

interface PropsFromState {
	//here is what we use from redux-store//state//InsuranceBuyerState
	// email: string;
	// name: string;
	// insurancepolicies: any[];
	//id: string;
	insurancePolicy: any;
}

interface PropsFromDispatch {
	//allow uss to use functions from redux-actions as if they were
	//props of this class component
	createInsurancePolicy: typeof createInsurancePolicy;
}
//--new combined props from state, own props, dispatch props -
//--as well as any props we want to pass - in a union type.

type IPolicyApplicationProps = PropsFromState &
	PropsFromDispatch &
	PropsForSelf &
	// RouteComponentProps<any> & //RouteComponentProps<{}>
	ConnectedReduxProps; //ConnectedReduxProps here is simply dispatch

interface IPolicyApplicationState {
	applicantCompanyFullName: string;
	applicantUSCI: string;
	applicantAddress: string;
	applicantAddressZipCode: string;
	applicantContactName: string;
	applicantPhoneNumber: string;
	applicantEmailAddress: string;
	applicantCapitalAtRegistration: number;
	applicantRegistrationYear: string;
	applicantRegistrationMonth: string;
	applicantRegistrationDay: string;
	applicantMainBusiness: string;
	applicantTypeOfBusiness: string;
	applicantNumOfEmployees: string;
	applicantOrganizationLevel: string;

	//
	insuredCompanyFullName: string;
	insuredUSCI: string;
	insuredAddress: string;
	insuredAddressZipCode: string;
	insuredContactName: string;
	insuredContactPhoneNumber: string;
	insuredCapitalAtRegistration: number;
	insuredMainBusiness: string;
	insuredTypeOfBusiness: string;
	insuredNumberOfEmployees: string;
	insuredOrganizationLevel: string;

	//
	invoiceAmount: number;
	priceTerm: string;
	insurancePlusPercentage: number;
	invoiceNumber: string;
	cargoPackageAndQuantity: string;
	cargoWeight: number;
	cargoNumberAndTypeOfContainers: string;
	cargoDescriptionOfGoods: string;
	amountInsured: number;
	ETDDate: string;
	vesselName: string;
	voyageNumber: string;
	fromPlace: string;
	viaPlace: string;
	toPlace: string;
	insuranceCoverageTypes: string[];
	numberOfOriginalPolicies: number;
	originalPolicyDeliveryAddressA: string;
	originalPolicyDeliveryAddressB: string;
	insuranceRatePercentage: number; //0-100
	insuranceWaiverOfClaimsPercentage: number;
	claimPayablePlaceChinese: string;
	claimPayablePlaceEnglish: string;
	applicationYear: string;
	applicationMonth: string;
	applicationDay: string;
}

class PolicyApplicationDtl extends React.Component<IPolicyApplicationProps, IPolicyApplicationState> {
	constructor(props: any) {
		super(props);
		this.state = {
			applicantCompanyFullName: "天津牛海进出口有限公司",
			applicantUSCI: "91120222MA06F1XP16",
			applicantAddress: "北京市海淀区大柳树路2号铁科院家属区南9楼641",
			applicantAddressZipCode: "100081",
			applicantContactName: "常崇义",
			applicantPhoneNumber: "13701328596",
			applicantEmailAddress: "NIUHAYMANAGEMENT@QQ.COM",
			applicantCapitalAtRegistration: 200.0,
			applicantRegistrationYear: "2019",
			applicantRegistrationMonth: "1",
			applicantRegistrationDay: "1",
			applicantMainBusiness: "进出口",
			applicantTypeOfBusiness: "",
			applicantNumOfEmployees: "1-19",
			applicantOrganizationLevel: "总部",

			//
			insuredCompanyFullName: "Whitby Ag Enterprises LLC",
			insuredUSCI: "无(美国公司)",
			insuredAddress: "720 W. Juniper Road, Mesa, Washington, 99343, USA",
			insuredAddressZipCode: "99343",
			insuredContactName: "Tom Long",
			insuredContactPhoneNumber: "+1-302-650-2629",
			insuredCapitalAtRegistration: 1000.0,
			insuredMainBusiness: "农、林、牧、渔业",
			insuredTypeOfBusiness: "",
			insuredNumberOfEmployees: "100-999",
			insuredOrganizationLevel: "总部",

			//
			invoiceAmount: 57778.22,
			priceTerm: "CIF",
			insurancePlusPercentage: 10,
			invoiceNumber: "104426",
			cargoPackageAndQuantity: "360捆(bales)",
			cargoWeight: 157.006,
			cargoNumberAndTypeOfContainers: "6个40尺高柜集装箱",
			cargoDescriptionOfGoods: "美国输华苜蓿干草",
			amountInsured: 63556.04,
			ETDDate: "06/15/2019",
			vesselName: "EVER SUMMIT",
			voyageNumber: "V.0215-083W",
			fromPlace: "Tacoma, Washington, USA",
			viaPlace: "",
			toPlace: "Qingdao, China",
			insuranceCoverageTypes: ["All Risks"],
			numberOfOriginalPolicies: 2,
			originalPolicyDeliveryAddressA:
				"内蒙古优然牧业有限责任公司，内蒙古呼和浩特市赛罕区河西路169号，邮编010070薛晓峰收，电话：18947195241",
			originalPolicyDeliveryAddressB:
				"北京市海淀区大柳树路2号铁科院家属区南9楼641，邮编，100081，常崇义先生收，电话：13701328596",
			insuranceRatePercentage: 0.05,
			insuranceWaiverOfClaimsPercentage: 0, // 0-100
			claimPayablePlaceChinese: "天津",
			claimPayablePlaceEnglish: "Tianjin",
			applicationYear: "2019",
			applicationMonth: "06",
			applicationDay: "13"
		};
	}

	componentDidMount() {}

	render() {
		return (
			<div>
				<div className="application-form-container">
					<div className="application-page-container"></div>
					{/* Content from https://word2cleanhtml.com */}
					<p className="p-center">
						<strong>货物运输保险投保单</strong> <strong></strong>
					</p>
					<p className="p-center">
						<strong>APPLICATION FORM FOR CARGO TRANSPORTATION INSURANCE</strong>
						<strong></strong>
					</p>
					<p>
						<strong></strong>
						<strong>投保单编号(NO.)</strong> <strong>:</strong>
					</p>
					<p>
						<strong>投保人、被保险人基本信息</strong> <strong></strong>
					</p>
					<div
					//  className="div-center"
					>
						<table width="1084" cellSpacing="0" cellPadding="0" border={1}>
							<tbody>
								<tr>
									<td rowSpan={4} width="54">
										<p className="p-center">
											<strong>投保人必填信息</strong> <strong></strong>
										</p>
									</td>
									<td width="130">
										<p className="p-center">
											<strong>全称</strong> <strong></strong>
										</p>
									</td>
									<td colSpan={3} width="490">
										<p>
											{/* 天津牛海进出口有限公司 */}
											{this.state.applicantCompanyFullName}
										</p>
									</td>
									<td colSpan={2} width="156">
										<p className="p-center">
											<strong>社会统一信用代码</strong> <strong></strong>
										</p>
									</td>
									<td width="254">
										<p>
											{/* 91120222MA06F1XP16 */}
											{this.state.applicantUSCI}
										</p>
									</td>
								</tr>
								<tr>
									<td width="130">
										<p className="p-center">
											<strong>联系地址</strong> <strong></strong>
										</p>
									</td>
									<td colSpan={3} width="490">
										<p>
											{/* 北京市海淀区大柳树路2号铁科院家属区南9楼641 */}
											{this.state.applicantAddress}
										</p>
									</td>
									<td colSpan={2} width="156">
										<p className="p-center">
											<strong>邮政编码</strong> <strong></strong>
										</p>
									</td>
									<td width="254">
										<p>
											{/* 100081 */}
											{this.state.applicantAddressZipCode}
										</p>
									</td>
								</tr>
								<tr>
									<td width="130">
										<p className="p-center">
											<strong>联系人</strong> <strong></strong>
										</p>
									</td>
									<td width="284">
										<p>
											{/* 常崇义 */}
											{this.state.applicantContactName}
										</p>
									</td>
									<td colSpan={2} width="206">
										<p className="p-center">
											<strong>联系人电话</strong> <strong></strong>
										</p>
									</td>
									<td colSpan={3} width="410">
										<p>
											{/* 13701328596 */}
											{this.state.applicantPhoneNumber}
										</p>
									</td>
								</tr>
								<tr>
									<td colSpan={7} width="1030">
										<p>
											<strong>如需使用电子保单功能，请填写联系人电子邮箱：</strong>
											<strong>{/* NIUHAYMANAGEMENT@QQ.COM */}</strong>
										</p>
									</td>
								</tr>
								<tr>
									<td rowSpan={3} width="54">
										<p className="p-center">
											<strong>投保人补充信息</strong>
										</p>
									</td>
									<td width="130">
										<p className="p-center">
											<strong>注册资金</strong> <strong></strong>
										</p>
									</td>
									<td colSpan={2} width="431">
										<p>
											{/* 200  */}
											{this.state.applicantCapitalAtRegistration}
											万元
										</p>
									</td>
									<td colSpan={2} width="176">
										<p className="p-center">
											<strong>注册日期</strong> <strong></strong>
										</p>
									</td>
									<td colSpan={2} width="293">
										<p>
											{/* 2018 年 09 月18 日 */}
											{this.state.applicantRegistrationYear}年{this.state.applicantRegistrationMonth}月
											{this.state.applicantRegistrationDay}日
										</p>
									</td>
								</tr>
								<tr>
									<td width="130">
										<p className="p-center">
											<strong>主营业务</strong> <strong></strong>
										</p>
										<p className="p-center">
											<strong>行业类型</strong> <strong></strong>
										</p>
									</td>
									<td colSpan={6} width="900">
										<p>
											{/* 进出口 */}
											{this.state.applicantMainBusiness}
										</p>
									</td>
									<td colSpan={6} width="900">
										<p>{this.state.applicantTypeOfBusiness}</p>
									</td>
								</tr>
								<tr>
									<td width="130">
										<p className="p-center">
											<strong>员工总数</strong> <strong></strong>
										</p>
									</td>
									<td colSpan={2} width="431">
										<p>
											{/* 1-19  */}
											{this.state.applicantNumOfEmployees}人
										</p>
									</td>
									<td colSpan={2} width="176">
										<p className="p-center">
											<strong>组织层级</strong> <strong></strong>
										</p>
										<p className="p-center">
											<strong>（非企业不填）</strong>
										</p>
									</td>
									<td colSpan={2} width="293">
										<p>
											{/* 总部 */}
											{this.state.applicantOrganizationLevel}
										</p>
									</td>
								</tr>
								<tr>
									<td colSpan={8} width="1084" valign="top">
										<p>
											<strong>被保险人与投保人信息不同（请您填写不同项）</strong> <strong></strong>
										</p>
										<p>
											<strong>被保险人</strong>
											<strong>如为境外注册公司或自然人，则被保险人信息可全部为选填项</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
								<tr>
									<td rowSpan={3} width="54">
										<p className="p-center">
											<strong>被保险人必填信息</strong> <strong></strong>
										</p>
									</td>
									<td width="130">
										<p className="p-center">
											<strong>全称</strong> <strong></strong>
										</p>
									</td>
									<td colSpan={3} width="490">
										<p>
											{/* Whitby Ag Enterprises LLC */}
											{this.state.insuredCompanyFullName}
										</p>
									</td>
									<td colSpan={2} width="156">
										<p className="p-center">
											<strong>社会统一信用代码</strong> <strong></strong>
										</p>
									</td>
									<td width="254">
										<p>
											{/* 无 (美国公司) */}
											{this.state.insuredUSCI}
										</p>
									</td>
								</tr>
								<tr>
									<td width="130">
										<p className="p-center">
											<strong>联系地址</strong> <strong></strong>
										</p>
									</td>
									<td colSpan={3} width="490">
										<p>
											{/* 720 W. Juniper Road, Mesa, Washington, 99343, USA */}

											{this.state.insuredAddress}
										</p>
									</td>
									<td colSpan={2} width="156">
										<p className="p-center">
											<strong>邮政编码</strong> <strong></strong>
										</p>
									</td>
									<td width="254">
										<p>
											{/* 99343 */}
											{this.state.insuredAddressZipCode}
										</p>
									</td>
								</tr>
								<tr>
									<td width="130">
										<p className="p-center">
											<strong>联系人</strong> <strong></strong>
										</p>
									</td>
									<td width="284">
										<p>
											{/* Tom Long */}
											{this.state.insuredContactName}
										</p>
									</td>
									<td colSpan={2} width="206">
										<p className="p-center">
											<strong>联系人电话</strong> <strong></strong>
										</p>
									</td>
									<td colSpan={3} width="410">
										<p>
											{/* +1-302-650-2629 */}
											{this.state.insuredContactPhoneNumber}
										</p>
									</td>
								</tr>
								<tr>
									<td rowSpan={3} width="54" valign="top">
										<p className="p-center">
											<strong>被 保 险 人 补 充 信 息</strong> <strong></strong>
										</p>
									</td>
									<td width="130">
										<p className="p-center">
											<strong>注册资金</strong> <strong></strong>
										</p>
									</td>
									<td colSpan={2} width="431">
										<p className="p-center">
											{/* 1000 */}
											{this.state.insuredCapitalAtRegistration}
											万元
										</p>
									</td>
									<td colSpan={2} width="176">
										<p className="p-center">
											<strong>注册日期</strong> <strong></strong>
										</p>
									</td>
									<td colSpan={2} width="293">
										<p className="p-center">年 月 日</p>
									</td>
								</tr>
								<tr>
									<td width="130">
										<p className="p-center">
											<strong>主营业务</strong> <strong></strong>
										</p>
										<p className="p-center">
											<strong>行业类型</strong> <strong></strong>
										</p>
									</td>
									<td colSpan={6} width="900" valign="top">
										<p>
											{/* 农、林、牧、渔业 */}
											{this.state.insuredMainBusiness}
										</p>
									</td>
									<td colSpan={6} width="900" valign="top">
										<p>{this.state.insuredTypeOfBusiness}</p>
									</td>
								</tr>
								<tr>
									<td width="130">
										<p className="p-center">
											<strong>员工总数</strong> <strong></strong>
										</p>
									</td>
									<td colSpan={2} width="431" valign="top">
										<p>
											{/* 100-999 */}
											{this.state.insuredNumberOfEmployees}
										</p>
									</td>
									<td colSpan={2} width="176">
										<p className="p-center">
											<strong>组织层级</strong> <strong></strong>
										</p>
										<p className="p-center">
											<strong>（非企业不填）</strong>
										</p>
									</td>
									<td colSpan={2} width="293">
										<p>
											□{/* 总部 */}
											{this.state.insuredOrganizationLevel}
										</p>
									</td>
								</tr>
								<tr style={{ height: "0px" }}>
									<td width="56">
										<br />
									</td>
									<td width="130">
										<br />
									</td>
									<td width="283">
										<br />
									</td>
									<td width="147">
										<br />
									</td>
									<td width="59">
										<br />
									</td>
									<td width="116">
										<br />
									</td>
									<td width="39">
										<br />
									</td>
									<td width="254">
										<br />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<br />
					<div
					// className="div-center"
					>
						<table width="1077" cellSpacing="0" cellPadding="0" border={1}>
							<tbody>
								<tr>
									<td colSpan={2} width="468" valign="top">
										<br />
										<p>
											发票金额Invoice Amount{" "}
											<strong>
												{/* 57,778.22
												 */}
												{this.state.invoiceAmount}
											</strong>
											<strong>美元</strong>
										</p>
									</td>
									<td width="352" valign="top">
										<p>
											价格条件Price Condition:
											{/* CIF */}
											{this.state.priceTerm}
										</p>
									</td>
									<td width="257" valign="top">
										<p>
											投保加成Plus:
											{/* 10% */}
											{this.state.insurancePlusPercentage}%
										</p>
									</td>
								</tr>
								<tr>
									<td width="292" valign="top">
										<p className="p-center">标记 &amp; 发票（合同）号</p>
										<p className="p-center">Marks &amp; Invoice (Contract) No.</p>
									</td>
									<td width="176" valign="top">
										<p className="p-center">包装与数量</p>
										<p className="p-center">Packing &amp; Quantity</p>
									</td>
									<td width="352" valign="top">
										<p className="p-center">投保货物项目</p>
										<p className="p-center">Description of Goods</p>
									</td>
									<td width="257" valign="top">
										<p className="p-center">投保金额</p>
										<p className="p-center">Amount Insured</p>
									</td>
								</tr>
								<tr>
									<td width="292" valign="top">
										<p className="p-center">
											{/* 104426 */}
											{this.state.invoiceNumber}
										</p>
									</td>
									<td width="176" valign="top">
										<p className="p-center">
											{this.state.cargoPackageAndQuantity}
											{/* 360捆(bales) */}
										</p>
										<p className="p-center">
											重量
											{/* 157.006公吨 */}
											{this.state.cargoWeight}
										</p>
										<p className="p-center">
											{/* 6个40尺高柜集装箱 */}
											{this.state.cargoNumberAndTypeOfContainers}
										</p>
									</td>
									<td width="352" valign="top">
										<p className="p-center">
											{/* 美国输华苜蓿干草 */}
											{this.state.cargoDescriptionOfGoods}
										</p>
									</td>
									<td width="257" valign="top">
										<p className="p-center">
											{/* 63,556.04 */}
											{this.state.amountInsured}
											美元
										</p>
									</td>
								</tr>
								<tr>
									<td colSpan={4} width="1077">
										<p>
											起运日期：
											{/* 06/15/2019  */}
											{this.state.ETDDate}
											运输工具：
											{/* EVER SUMMIT  */}
											{this.state.vesselName}
											航次/航班/车号：
											{this.state.voyageNumber}
											{/* V.0215-083W */}
										</p>
										<p>Slg. on or Abt. Per Conveyance 　 Voy. No.</p>
									</td>
								</tr>
								<tr>
									<td colSpan={4} width="1077">
										<p>
											运输路线： 自{/* Tacoma, Washington USA  */}
											{this.state.fromPlace}经{/*  */}
											{this.state.viaPlace}至{/* Qingdao, China */}
											{this.state.toPlace}
										</p>
										<p>Route From Via To</p>
									</td>
								</tr>
								<tr>
									<td colSpan={4} width="1077" valign="top">
										<p>投保险别 Please Indicate the Conditions &amp;/or Special Coverage</p>
										<p>
											{/* 一切险 */}

											{this.state.insuranceCoverageTypes}
										</p>

										<p>
											显示保单正本份数Numbers of the Original Policy （{/* 2 */}
											{this.state.numberOfOriginalPolicies}）
										</p>
										<p>
											一本正本请快递到：
											{this.state.originalPolicyDeliveryAddressA}
											{/* 内蒙古优然牧业有限责任公司，内蒙古呼和浩特市赛罕区河西路169号，邮编010070
											薛晓峰收，电话：18947195241 */}
										</p>
										<p></p>
										<p>
											另外一本正本请快递到：
											{/* 北京市海淀区大柳树路2号铁科院家属区南9楼641，邮编，100081，常崇义先生收，电话：13701328596 */}
											{this.state.originalPolicyDeliveryAddressB}
										</p>
									</td>
								</tr>
								<tr>
									<td colSpan={4} width="1077">
										<p>
											费率：
											{/* 0.05% */}
											{this.state.insuranceRatePercentage}% 免赔额/率：0
										</p>
										<p>
											Rate □ 每次事故绝对免赔保额的
											{/* 0 */}
											{this.state.insuranceWaiverOfClaimsPercentage} %.
										</p>
									</td>
								</tr>
								<tr>
									<td colSpan={4} width="1077">
										<p>
											赔偿地点：
											{/* 天津 */}
											{this.state.claimPayablePlaceChinese}
										</p>
										<p>
											Claim Payable at:
											{/* Tianjin */}
											{this.state.claimPayablePlaceEnglish}
										</p>
									</td>
								</tr>
								<tr>
									<td colSpan={4} width="1077">
										<p>
											{/* <a name="OLE_LINK2"></a>
											<a name="OLE_LINK1"> */}
											<strong>理赔服务提示：如您在保险合同生效期间不幸发生保险事故，请在</strong>
											{/* </a> */}
											<strong>48</strong>
											<strong>小时内通过热线电话</strong>
											<strong>95500</strong>
											<strong>报案，我们将在接到您报案后的</strong>
											<strong>1</strong>
											<strong>个工作日内与您联系，指导并协助您办理理赔事宜。</strong>
											<strong></strong>
										</p>
										<p>
											<strong>
												Tips on Claim Service: Should any accident or fortuity occur during effective term of this
												insurance contract, please kindly notice us via Hotline 955
											</strong>
											<strong>00</strong>
											<strong>
												within 48 hours. We shall contact you to guide and assist claim handling within 1 working day
												upon receipt of your message.
											</strong>
										</p>
									</td>
								</tr>
								<tr>
									<td colSpan={4} width="1077" valign="top">
										<p>
											<strong>请如实告知下列情况：（如是在[ ]中打√）</strong>
										</p>
										<p>
											1. 货物包装 袋装/箱装[ <strong>√</strong> ] 散装/裸装[ ] 冷藏[ ] 捆扎[ <strong>√</strong> ] 罐装[
											]
										</p>
										<p>
											2. 是否为全新货物 是[ <strong>√</strong> ] 否[ ] 是否为舱面货 是[ ] 否[ ]
										</p>
										<p>
											3. 集装箱种类 普通[ <strong>√</strong> ] 开顶[ ] 框架[ ] 平板[ ] 冷藏[ ]
										</p>
										<p>
											4. 运输工具 海轮[ <strong>√</strong> ] 飞机[ ] 火车[ ] 汽车[ ] 内河船[ ] 驳船[ ]
										</p>
										<p>
											5. 船舶资料 船级 <u></u> 建造年月 <u></u>
										</p>
									</td>
								</tr>
								<tr>
									<td colSpan={4} width="1077" valign="top">
										<p className="p-center">
											<strong>保险人（保险公司）提示</strong> <strong></strong>
										</p>
										<p>
											<strong>
												请您仔细阅读保险条款，尤其是黑体字标注部分的条款内容，并听取保险公司业务人员的说明，如对保险公司业务人员的说明不明白或有异议的，请在填写本投保单之前向保险公司业务人员进行询问，如未询问，视同已经对条款内容完全理解并无异议。
											</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
								<tr>
									<td colSpan={4} width="1077" valign="top">
										<p className="p-center">
											<strong>投保人声明</strong> <strong></strong>
										</p>
										<p>
											<strong>投保人及被保险人兹声明所填上述内容（包括投保单及投保附件）属实。</strong>
											<strong></strong>
										</p>
										<p>
											<strong>
												本人已经收悉并仔细阅读保险条款，尤其是黑体字部分的条款内容，并对保险公司就保险条款内容的说明和提示完全理解，没有异议，申请投保。
											</strong>
											<strong></strong>
										</p>
										<p>
											<strong>投保人签章：</strong>
											<strong></strong>
											<strong>　　　　</strong>
											<strong></strong>
											<strong>投保日期：</strong>
											<strong>
												{/* 2019 */}
												{this.state.applicationYear}
											</strong>
											<strong>年</strong>
											<strong>
												{/* 6 */}
												{this.state.applicationMonth}
											</strong>
											<strong>月</strong>
											<strong>
												{/* 13 */}
												{this.state.applicationDay}
											</strong>
											<strong>日</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p>
						<strong>以下内容由保险公司填写</strong> <strong></strong>
					</p>
					<table width="1077" cellSpacing="0" cellPadding="0" border={1}>
						<tbody>
							<tr>
								<td width="1077" valign="top">
									<p>协议编号[ ] 代理点编号[ ] 客户代码[ ] 共保信息[ ]</p>
									<p>回分情况[ ] 最低保费 [ ] 结算币种[ ] 经办人 [ ]</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ insurancepolicy }: ApplicationState) => {
	return {
		insurancePolicy: insurancepolicy.insurancePolicy
	};
};

const mapDispatchToProps = {
	createInsurancePolicy: createInsurancePolicy
};

function PolicyApplication(props: any) {
	let navigate = useNavigate();
	const params = useParams();
	return <PolicyApplicationDtl {...{ ...props, match: { params } }} navigate={navigate} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(PolicyApplication);
