//reducer is used to change state based on type of action and previous state
import {
	// combineReducers,
	Reducer
	//  AnyAction
} from "redux";
import { InsuranceBuyerState, InsuranceBuyerActionTypes } from "./InsuranceBuyerTypes";

//create Type-safe initialState
export const initialState: InsuranceBuyerState = {
	id: "",
	name: "",
	email: "", //by default english
	insurancepolicies: [],
	companyName: "",
	companyPhoneNumber: "",
	companySize: "",
	companyWebsite: "",
	companyRegistrationYear: "",
	companyRevenue: ""
};

//here we must need redux 4, otherwise Reducer does not take AnyAction as second argument type
//this postsReducer here is a function that takes the state and action to update the state
const insurancebuyerReducer: Reducer<InsuranceBuyerState> = (state = initialState, action: any) => {
	switch (action.type) {
		//case fetch success
		case InsuranceBuyerActionTypes.FETCH_INSURANCEBUYERS_SUCCESS:
			return {
				...state,
				insurancepolicies: action.data //must pass back myOptions here from Thunk or Saga
			};

		//case fetch failure:
		case InsuranceBuyerActionTypes.FETCH_INSURANCEBUYERS_ERROR:
			return {
				...state,
				insurancepolicies: []
			};

		//case create success:
		case InsuranceBuyerActionTypes.CREATE_INSURANCEBUYER_SUCCESS:
			//here we should update the state using
			return {
				...state,
				id: action.payload.insurancebuyer._id,
				name: action.payload.insurancebuyer.name,
				email: action.payload.insurancebuyer.email
			};
		//the data from back-end after new insurancebuyer is added to Database

		case InsuranceBuyerActionTypes.CREATE_INSURANCEBUYER_ERROR:
			//case create failure:
			return state; // no change to the state

		case InsuranceBuyerActionTypes.CREATE_INSURANCEBUYER_ONBOARD_SUCCESS:
			//here we should update the state using
			return { ...state, insurancebuyer: action.payload.insurancebuyer };
		//the data from back-end after new insurancebuyer is added to Database

		case InsuranceBuyerActionTypes.CREATE_INSURANCEBUYER_ONBOARD_ERROR:
			//case create failure:
			return state; // no change to the state

		default: //by default, no change to state
			return state;
	}
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export default insurancebuyerReducer;
