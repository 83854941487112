//actions here define the type and the action for each Type identified
import { InsurancePolicyActionTypes } from "./InsurancePolicyTypes";
//actions on CRUD of newsletters
//C //Create
export const createInsurancePolicySuccess = (insurancePolicy: any) => {
	//plain action (no functions, only data)
	return {
		type: InsurancePolicyActionTypes.CREATE_INSURANCEPOLICY_SUCCESS,
		payload: { insurancePolicy: insurancePolicy }
	};
};

export const createInsurancePolicyError = (error: any) => {
	return { type: InsurancePolicyActionTypes.CREATE_INSURANCEPOLICY_ERROR, error };
};

//R //Read
export const fetchInsurancePoliciesSucess = (myOptions: string[]) => {
	return {
		type: InsurancePolicyActionTypes.FETCH_INSURANCEPOLICIES_SUCCESS
		// myOptions: myOptions
	};
};
export const fetchInsurancePoliciesError = (error: any) => {
	return { type: InsurancePolicyActionTypes.FETCH_INSURANCEPOLICIES_ERROR, error };
};

//U //update
export const updateInsurancePolicySuccess = (insurancePolicy: any) => {
	//plain action (no functions, only data)
	return {
		type: InsurancePolicyActionTypes.UPDATE_INSURANCEPOLICY_SUCCESS,
		payload: { insurancePolicy: insurancePolicy }
	};
};

export const updateInsurancePolicyError = (error: any) => {
	return { type: InsurancePolicyActionTypes.UPDATE_INSURANCEPOLICY_ERROR, error };
};
//D //delete
