import { combineReducers, Dispatch, Action, AnyAction } from "redux";
import { all, fork } from "redux-saga/effects";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";

import newsletterSaga from "./NewsLetter/NewsLetterSagas";
import { NewsLetterState } from "./NewsLetter/NewsLetterTypes";
import newsletterReducer from "./NewsLetter/NewsLetterReducer";

import subscriberSaga from "./Subscriber/SubscriberSagas";
import { SubscriberState } from "./Subscriber/SubscriberTypes";
import subscriberReducer from "./Subscriber/SubscriberReducer";

import insurancebuyerSaga from "./InsuranceBuyer/InsuranceBuyerSagas";
import { InsuranceBuyerState } from "./InsuranceBuyer/InsuranceBuyerTypes";
import insurancebuyerReducer from "./InsuranceBuyer/InsuranceBuyerReducer";

import insurancepolicySaga from "./InsurancePolicy/InsurancePolicySagas";
import { InsurancePolicyState } from "./InsurancePolicy/InsurancePolicyTypes";
import insurancepolicyReducer from "./InsurancePolicy/InsurancePolicyReducer";

// The top-level state object
export interface ApplicationState {
	newsletter: NewsLetterState;
	subscriber: SubscriberState;
	insurancebuyer: InsuranceBuyerState;
	insurancepolicy: InsurancePolicyState;
	router: RouterState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface ConnectedReduxProps<A extends Action = AnyAction> {
	dispatch: Dispatch<A>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const createRootReducer = (history: History) =>
	combineReducers({
		newsletter: newsletterReducer,
		subscriber: subscriberReducer,
		insurancebuyer: insurancebuyerReducer,
		insurancepolicy: insurancepolicyReducer,
		router: connectRouter(history)
	});

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*

export function* rootSaga() {
	yield all([fork(newsletterSaga), fork(subscriberSaga), fork(insurancebuyerSaga), fork(insurancepolicySaga)]);
}
